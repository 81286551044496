import { Form, Input, Button } from "antd"
import { apiUser } from "api"
import { useEffect, useRef, useState } from "react"
import LabelInput from "components/ui/LabelInput"
import { setCookieAuth } from "lib/cookie"
import { useStore } from "components/ui"
import { useNavigate } from "react-router-dom"
import axios from "axios"
import "./Login.scss"
import AlertError from "components/AlertError"
import { useTranslation } from "react-i18next"

const baseDomain = process.env.REACT_APP_ID_URL
const baseDomainCore = process.env.REACT_APP_CORE_URL

const OTPCode = ({
  email,
  user,
  authenType,
  firstLogin,
  existLogin,
  onNext,
  onDirrectToRecoveryCode,
  onBack
}) => {
  const { t } = useTranslation()
  const navigate = useNavigate()
  const { setAuthStore } = useStore()
  const [loading, setLoading] = useState(false)
  const [error, setError] = useState("")
  const [isSubmited, setIsSubmited] = useState(false)

  const onFinish = async (values) => {
    setError("")
    setLoading(true)
    try {
      if (existLogin) {
        let res = {}
        if (authenType === 1) {
          res = await apiUser.verifyCode({ email: email, code: values.code })
        } else {
          res = await apiUser.verifyCodeGoogleAuth({
            user_id: user?.id,
            code: values.code
          })
        }
        fetchBaseApi(res?.data?.token)
      } else {
        const { data } = await apiUser.verifyCode({ email: email, code: values.code })
        onNext(data?.token, data?.user)
      }
    } catch (error) {
      setLoading(false)
      const { data } = error.response
      setError(t(data?.error_message[0]))
    } finally {
    }
  }

  const onResend = async () => {
    setLoading(true)
    try {
      // Make a login API call using the provided credentials
      await apiUser.sendEmail({ email: email })
    } catch (error) {
      console.log({ error })
    } finally {
      setLoading(false)
    }
  }

  async function fetchBaseApi(token) {
    const { access_token, refresh_token } = token
    let userInfo = {}
    setLoading(true)
    axios({
      url: `${baseDomain}/api/v1/account/view/profile/`,
      method: "get",
      headers: {
        Authorization: `Bearer ${access_token}`,
        "Content-Type": "application/json"
      }
    })
      .then((response) => {
        const { data } = response.data
        userInfo = data
        return axios({
          url: `${baseDomainCore}/api/v1/staff/configs/?page_num=1&page_size=1000`,
          method: "get",
          headers: {
            Authorization: `Bearer ${access_token}`,
            "Content-Type": "application/json"
          }
        })
      })
      .then((secondResponse) => {
        const { data } = secondResponse
        setLoading(false)

        let baseData = {
          user: userInfo,
          settings: data?.data,
          permission: [],
          access_token: access_token
        }
        setCookieAuth({ access_token, refresh_token })
        setAuthStore(baseData)
        navigate("/dashboard")
      })
      .catch((err) => {
        setLoading(false)
        console.log(err)
      })
  }

  const onChange = () => {
    setIsSubmited(true)
    setError("")
  }

  return (
    <div className="">
      <h3 className="text-[24px] text-blue-dark font-bold text-center mt-8 mb-6">
        {firstLogin ? t("Two-Factor Authentication") : t("Forgot password")}
      </h3>

      {error !== "" && <AlertError error={error} />}

      <div className="text-center text-[#646970]">
        {t(
          authenType === 1
            ? t("Check the email that’s associated with your account for the verification code.")
            : t("Enter the 6-digit code generated by your app to confirm your action")
        )}
      </div>
      {/* {user?.two_fa === 1 && <div className="text-center text-[#646970] font-bold">{email}</div>} */}

      <div className="mt-[32px]">
        <Form
          name="normal_login"
          initialValues={{ remember: true }}
          onFinish={onFinish}
          layout="vertical"
        >
          <Form.Item
            style={{ marginBottom: 8 }}
            label={<LabelInput label={t("Verification code")} required={true} />}
            required={false}
            name="code"
            rules={[{ required: true, message: t("Verification code is required") }]}
          >
            <Input size="large" placeholder={t("Enter the 6-digit code")} onChange={onChange} />
          </Form.Item>
          <div className="mb-1">
            <BoxResend onResend={onResend} />
          </div>
          {existLogin && authenType === 2 && (
            <div>
              <span>{t("Don’t have your device?")} </span>
              <span
                className="cursor-pointer text-primary-700 font-[700]"
                onClick={onDirrectToRecoveryCode}
              >
                {t("Enter a recovery code")}
              </span>
            </div>
          )}

          {/* Submit button */}
          <Form.Item>
            <Button
              type="primary"
              size="large"
              htmlType="submit"
              className="w-full mt-5 !rounded-lg"
              loading={loading}
              disabled={!isSubmited}
            >
              {firstLogin ? t("Continue") : t("Verify")}
            </Button>
          </Form.Item>
        </Form>
      </div>
      <div className="text-center mt-[16px]">
        <span>
          {t("Back to")} {/* eslint-disable-next-line jsx-a11y/anchor-is-valid */}
          <a className="cursor-pointer text-primary-700" onClick={onBack}>
            {t("Sign in")}
          </a>
        </span>
      </div>
    </div>
  )
}

export default OTPCode
function BoxResend({ maxCount = 60, onResend }) {
  const { t } = useTranslation()
  const [count, setCount] = useState(maxCount)
  const __time = useRef()

  function handleResend() {
    setCount(maxCount)
    onResend()
    __time.current = setInterval(() => {
      console.log(count)
      setCount((c) => c - 1)
    }, 1000)
  }

  useEffect(() => {
    __time.current = setInterval(() => {
      setCount((c) => c - 1)
    }, 1000)
    return () => clearInterval(__time.current)
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [])

  useEffect(() => {
    if (count < 1) clearInterval(__time.current)
  }, [count])

  return (
    <div>
      {count > 0 ? (
        <div className="flex justify-end gap-2">
          {t("Resend in")} <span className="text-primary-600 font-bold">{count}</span> {t("sec")}
        </div>
      ) : (
        <div className="flex justify-end">
          <div onClick={handleResend} className="text-primary-600 cursor-pointer">
            {t("Resend")}
          </div>
        </div>
      )}
    </div>
  )
}
