import Client from "../client/Client"
import version from "../version"
const resource = version + "/staff/packages"
const resourceModule = version + "/staff/modules"

function gets(params) {
  return Client.get(`${resource}/`, { params })
}
function get(id, package_version_id) {
  return Client.get(`${resource}/${id}/versions/${package_version_id}/`)
}
function getPackageVersions(package_id, params) {
  return Client.get(`${resource}/${package_id}/versions/`, { params })
}
function getModules(params) {
  return Client.get(`${resourceModule}/all/`, { params })
}
function create(data) {
  return Client.post(`${resource}/`, data)
}
function createWithNewVersion(package_id, data) {
  return Client.post(`${resource}/${package_id}/versions/`, data)
}
function update(id, package_version_id, data) {
  return Client.put(`${resource}/${id}/versions/${package_version_id}/`, data)
}
function remove(id) {
  return Client.delete(`${resource}/${id}/`)
}
function updateStatus(id, data) {
  return Client.put(`${resource}/${id}/change-status/`, data)
}
function getGlobal(id) {
  return Client.get(`${resource}/${id}/versions/latest/`)
}

const api = {
  gets,
  get,
  getModules,
  getPackageVersions,
  create,
  createWithNewVersion,
  update,
  remove,
  updateStatus,
  getGlobal
}
export default api
