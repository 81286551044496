const key = {
  SET_AUTH: "SET_AUTH",
  LOG_OUT: "LOG_OUT",
  SET_PROJECT: "SET_PROJECT",
  SET_LOCALE: "SET_LOCALE",
  SET_MENU_OPTION: "SET_MENU_OPTION",
  CALLING: "CALLING"
}

export default key
