import { Button, ConfigProvider } from "antd"
import { hexToRgba } from "lib/function"
import useStore from "../Context"
import { myTheme } from "lib/theme"

export default function ButtonSecondary({ name, children, ...rest }) {
  const { settingTheme } = useStore()
  let colorPrimary = settingTheme?.color || myTheme.colorPrimary
  return (
    <div>
      <ConfigProvider
        theme={{
          components: {
            Button: {
              // controlHeight: 36,
              colorPrimary: colorPrimary,
              defaultColor: colorPrimary,
              defaultBg: "#E3F1F8",
              defaultHoverBg: hexToRgba(colorPrimary, 0.4),
              defaultActiveBg: "#9fd6f2",
              lineWidth: 0
            }
          }
        }}
      >
        <Button style={{ fontWeight: 600 }} {...rest}>
          {children}
        </Button>
      </ConfigProvider>
    </div>
  )
}
