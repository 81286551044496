import { Button, Modal } from "antd"
import { getTimeFromLocale } from "lib/function"
import { useTranslation } from "react-i18next"
import { Link } from "react-router-dom"

export default function ModalUpdateApproval({ open, onClose, item }) {
  const { t } = useTranslation()
  const { body, title, created_at, upload_url } = item

  // JSX rendering for the component
  return (
    <Modal
      title={t("Notification Detail")}
      open={open}
      onCancel={onClose}
      footer={
        <div className="flex justify-end gap-4 border-t pt-5">
          <Button type="default" onClick={onClose}>
            {t("Cancel")}
          </Button>
        </div>
      }
    >
      <div className="my-4">
        <div className="text-[16px] text-medium">{title}</div>
        {/* <div className="mt-2 text-[16px] text-[#646970]">
          {t("Theresa Webb has sent you a message")}
        </div> */}
        <div className="mt-2 p-3 border border-[#C2C2C2] rounded-[8px] overflow-y-auto max-h-[300px]">
          <div className="text-[16px] text-[#646970]" dangerouslySetInnerHTML={{ __html: body }} />
          {upload_url && typeof upload_url === "object" && upload_url.length > 0 && (
            <div className="mt-4">
              <div className="">
                <span className="text-[16px] text-[#646970]">
                  {upload_url.length} {t("attachment files")}
                </span>
              </div>
              {upload_url.map((v, index) => (
                <Link key={index} to={v?.url} target="_blank" onClick={(e) => e.stopPropagation()}>
                  <div className="flex items-start gap-1">
                    <span className="mt-1">{icLink}</span>
                    <span className="!text-[#4DABD1]">{v?.name}</span>
                  </div>
                </Link>
              ))}
            </div>
          )}
        </div>

        <div className="mt-2 flex gap-4 items-center">
          <div className="text-[16px] text-[#646970]">{t("Date and time")}</div>
          <div>{getTimeFromLocale(created_at)}</div>
        </div>
      </div>
    </Modal>
  )
}

const icLink = (
  <svg width="16" height="16" viewBox="0 0 16 16" fill="none" xmlns="http://www.w3.org/2000/svg">
    <path
      d="M9.10678 10.7379C9.07993 10.7114 9.04367 10.6964 9.00589 10.6964C8.9681 10.6964 8.93184 10.7114 8.90499 10.7379L6.82999 12.8129C5.86928 13.7737 4.24785 13.8754 3.18714 12.8129C2.12464 11.7504 2.22642 10.1308 3.18714 9.17009L5.26214 7.09509C5.31749 7.03973 5.31749 6.94866 5.26214 6.8933L4.55142 6.18259C4.52457 6.156 4.48832 6.14109 4.45053 6.14109C4.41274 6.14109 4.37649 6.156 4.34964 6.18259L2.27464 8.25759C0.763923 9.7683 0.763923 12.2129 2.27464 13.7219C3.78535 15.2308 6.22999 15.2326 7.73892 13.7219L9.81392 11.6469C9.86928 11.5915 9.86928 11.5004 9.81392 11.4451L9.10678 10.7379ZM13.7246 2.27366C12.2139 0.762946 9.76928 0.762946 8.26035 2.27366L6.18357 4.34866C6.15698 4.37551 6.14207 4.41177 6.14207 4.44955C6.14207 4.48734 6.15698 4.5236 6.18357 4.55045L6.89249 5.25938C6.94785 5.31473 7.03892 5.31473 7.09428 5.25938L9.16928 3.18438C10.13 2.22366 11.7514 2.12188 12.8121 3.18438C13.8746 4.24688 13.7729 5.86652 12.8121 6.82723L10.7371 8.90223C10.7106 8.92908 10.6956 8.96534 10.6956 9.00313C10.6956 9.04091 10.7106 9.07717 10.7371 9.10402L11.4479 9.81473C11.5032 9.87009 11.5943 9.87009 11.6496 9.81473L13.7246 7.73973C15.2336 6.22902 15.2336 3.78438 13.7246 2.27366ZM9.75142 5.50402C9.72457 5.47743 9.68832 5.46252 9.65053 5.46252C9.61275 5.46252 9.57649 5.47743 9.54964 5.50402L5.50499 9.54688C5.47841 9.57373 5.46349 9.60998 5.46349 9.64777C5.46349 9.68555 5.47841 9.72181 5.50499 9.74866L6.21214 10.4558C6.26749 10.5112 6.35857 10.5112 6.41392 10.4558L10.4568 6.41295C10.5121 6.35759 10.5121 6.26652 10.4568 6.21116L9.75142 5.50402Z"
      fill="#646970"
    />
  </svg>
)
