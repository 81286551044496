import Client from "../client/ClientHideMessage"
import version from "../version"
const resource = version + "/client/domains"

function gets(params) {
  return Client().get(`${resource}/`, { params })
}
function getVerifyInfo(id) {
  return Client().get(`${version}/client/organizations/${id}/domain-verify-info/`)
}
function get(id, params) {
  return Client().get(`${resource}/${id}/`, { params })
}
function update(id, data) {
  return Client().put(`${resource}/${id}/`, data)
}
function verify(id, data) {
  return Client(true).put(`${resource}/${id}/verify/`, data)
}
function remove(id) {
  return Client().put(`${resource}/${id}/remove/`)
}

const api = {
  gets,
  get,
  getVerifyInfo,
  update,
  verify,
  remove
}

export default api
