import Client from "../client/Client"
import version from "../version"
const resource = version + "/client"

function getALL(params) {
  return Client.get(`${resource}/billings/`, { params })
}
function gets(id, params) {
  return Client.get(`${resource}/${id}/billings/`, { params })
}
function getTransactions(id, params) {
  return Client.get(`${resource}/billings/${id}/transactions/`, { params })
}
function getLatest(id, params) {
  return Client.get(`${resource}/${id}/billings/latest`, { params })
}
function get(params) {
  return Client.get(`${resource}/billings/latest/`, { params })
}
function exportList(data) {
  return Client.post(`${resource}/billings/export-csv/`, data)
}
function exportListTransaction(billing_id, data) {
  return Client.post(`${resource}/billings/${billing_id}/transactions/export-csv/`, data)
}

function updatePackage(id, data) {
  return Client.put(`${resource}/clients/${id}/change-package/`, data)
}

function getBillingFromClient(client_id, params) {
  return Client.get(`${resource}/clients/${client_id}/billing/`, { params })
}
function updateStatus(billing_id, billing_payment_method_id) {
  return Client.put(
    `${resource}/billings/${billing_id}/payment-methods/${billing_payment_method_id}/change-status/`
  )
}
function getPayments(params) {
  return Client.get(`${resource}/billing/payment-methods/`, { params })
}

function createPayment(data) {
  return Client.post(`${resource}/billing/payment-methods/`, data)
}

function updatePayment(id, data) {
  return Client.put(`${resource}/billing/payment-methods/${id}/`, data)
}
function updateStatusPayment(id, data) {
  return Client.put(`${resource}/billing/payment-methods/${id}/change-status/`, data)
}
function getBillingAddress(params) {
  return Client.get(`${resource}/billing/setting/`, { params })
}
function updateBillingAddress(data) {
  return Client.put(`${resource}/billing/setting/`, data)
}

function payNow(billing_id, data) {
  return Client.put(`${resource}/billings/${billing_id}/payment/`, data)
}
function invoice(id, params) {
  return Client.get(`${resource}/billings/${id}/invoice/`, { params })
}
const api = {
  getALL,
  gets,
  getLatest,
  get,
  exportList,
  exportListTransaction,
  createPayment,
  updatePackage,
  getBillingFromClient,
  getTransactions,
  updateStatus,
  updatePayment,
  getPayments,
  updateStatusPayment,
  getBillingAddress,
  updateBillingAddress,
  payNow,
  invoice
}

export default api
