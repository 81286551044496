import Client from "../client/Client"
import version from "../version"
const resource = version + "/client/support-tickets"

function gets(params) {
  return Client.get(`${resource}/`, { params })
}
function get(id, params) {
  return Client.get(`${resource}/${id}/`, { params })
}
function exportList(params) {
  return Client.get(`${resource}/`, { params })
}
function create(data) {
  return Client.post(`${resource}/`, data)
}
function update(id, data) {
  return Client.put(`${resource}/${id}/`, data)
}
function exportClients(params) {
  return Client.get(`${resource}/export-csv/`, { params })
}
function getClientDetail() {
  return Client.get(`${version}/client/me/`)
}

function updateStatus(id, data) {
  return Client.put(`${resource}/${id}/change-status/`, data)
}

function getComments(id, params) {
  return Client.get(`${resource}/${id}/comments/`, { params })
}
function getComment(id, params) {
  return Client.get(`${resource}/${id}/comments/`, { params })
}
function createComment(id, data) {
  return Client.post(`${resource}/${id}/comments/`, data)
}
function updateComment(id, data) {
  return Client.put(`${resource}/${id}/comments`, data)
}
function updateLike(id, comment_id, data) {
  return Client.put(`${resource}/${id}/comments/${comment_id}/like/`, data)
}
const api = {
  gets,
  get,
  exportList,
  create,
  update,
  exportClients,
  getClientDetail,
  updateStatus,
  getComments,
  getComment,
  createComment,
  updateComment,
  updateLike
}

export default api
