import React, { useState } from "react"
import Icon from "@ant-design/icons"
import MyHeader from "./Header/Header"
import { useStore } from "components/ui"
import Navbar from "./Navbar"
import menus from "pages/Authen/menus"
import "./index.css"
function convertMenus(menus, initPermission, menuOption) {
  let __menus = []
  let permission = initPermission.filter((i) => i.includes("view"))

  for (let i = 0; i < menus.length; i++) {
    const menu = menus[i]
    const IconX = () => <span dangerouslySetInnerHTML={{ __html: menu.icon }} />
    const IconActiveX = () => <span dangerouslySetInnerHTML={{ __html: menu.iconActive }} />
    let __children = []
    const newChildren = menu.sub_menu
    const isView = !!permission.find((i) => i === menu.code) || newChildren?.length > 0
    if (isView) {
      if (newChildren?.length > 0) {
        for (let j = 0; j < newChildren?.length; j++) {
          const IconY = () => <span dangerouslySetInnerHTML={{ __html: newChildren[j].icon }} />
          const privateMenu = newChildren[j]?.private
          let isLock = false
          if (privateMenu && menuOption && !menuOption[privateMenu]) isLock = true
          const item = {
            url: menu.url + newChildren[j].url,
            title: newChildren[j].title,
            isLock,
            icon: <Icon component={IconY} />
          }
          const isViewSub = !!permission.find((i) => i === newChildren[j].code)
          if (isViewSub) __children.push(item)
        }
        if (__children.length) {
          __menus.push({
            url: menu.url,
            title: menu.title,
            icon: <Icon component={IconX} />,
            iconActive: <Icon component={IconActiveX} />,
            children: __children
          })
        }
      } else {
        __menus.push({
          url: menu.url,
          title: menu.title,
          icon: <Icon component={IconX} />,
          iconActive: <Icon component={IconActiveX} />,
          children: []
        })
      }
    }
  }

  return __menus
}

function MyLayout({ children }) {
  const isShow = localStorage.getItem("isShow")
  const { user, permission, settingTheme, menuOption } = useStore()
  const [isNav, setIsNav] = useState(isShow === "false" ? false : true)

  function togleSidebar(e) {
    localStorage.setItem("isShow", !isNav)
    setIsNav((c) => !c)
  }

  return (
    <div className="flex min-h-screen">
      <Navbar
        settingTheme={settingTheme}
        items={convertMenus(menus, permission, menuOption)}
        togleSidebar={togleSidebar}
        isNav={isNav}
      />
      <section className="flex-grow w-0 bg_layout_content">
        <header className="flex justify-end bg-white border-b py-2 pr-6 pl-4 w-full sticky top-0 z-20">
          <MyHeader user={user} />
        </header>
        <div className="px-6 py-4">{children}</div>
      </section>
    </div>
  )
}
export default MyLayout
