import Client from "../client/ClientControlIDM"
import ClientCommon from "../client/ClientCommon"
import version from "../version"
// const resource = version + "/internal/user/view"

const login = (data) => {
  return Client(true).post(`/api/v1/account/partner/login/`, data)
}
const logout = (data) => {
  return Client().post(`/api/v1/account/logout/`, data)
}
function getPasswordSetting(params) {
  return Client().get(`${version}/config/password-policy/`, { params })
}
function getUserInfo() {
  return Client().get(`${version}/account/view/profile/`)
}
function sendEmail(data) {
  return Client(true).post(`${version}/mail/generate/`, data)
}
function generateSecretKey(userId, data) {
  return Client().put(`${version}/google-auth/update-secret-key/${userId}/`, data)
}
function regenerateRecoveryCode(data) {
  return Client().post(`${version}/account/regenerate-recovery-code/`, data)
}
function verifyCode(data) {
  return Client(true).post(`${version}/mail/verify/`, data)
}
function verifyCodeGoogleAuth(data) {
  return Client(true).post(`${version}/google-auth/validate/`, data)
}
function verifyCodeProfile(data) {
  return Client().post(`${version}/otp/gmail/`, data)
}
function verifyCodeGoogleAuthProfile(data) {
  return Client().post(`${version}/otp/authencator/`, data)
}
function resetPassword(data) {
  return Client(true).put(`${version}/account/reset-password/`, data)
}
function changePassword(data) {
  return Client().put(`${version}/account/change-password/`, data)
}
function updateProfile(data) {
  return Client().put(`${version}/account/update/profile/`, data)
}
function removeAccount() {
  return Client().put(`${version}/account/revoke/`)
}
function validatePassword(data) {
  return Client().post(`${version}/account/confirm-password/`, data)
}
function lastActivity(params) {
  return ClientCommon.get(`${version}/activity/latest/`, { params })
}

const api = {
  login,
  logout,
  getPasswordSetting,
  getUserInfo,
  changePassword,
  updateProfile,
  generateSecretKey,
  regenerateRecoveryCode,
  sendEmail,
  verifyCode,
  verifyCodeGoogleAuth,
  verifyCodeProfile,
  verifyCodeGoogleAuthProfile,
  resetPassword,
  removeAccount,
  validatePassword,
  lastActivity
}

export default api
