import { Tag } from "antd"
import { hexToRgba } from "lib/function"

export default function TagApprovalStatus({ status, t }) {
  function getValue(key) {
    switch (status) {
      case 0:
        if (key === "color") {
          return "#FCBF25"
        }
        return t("Pending")
      case 1:
        if (key === "color") {
          return "#12B981"
        }
        return t("Approved")
      case 2:
        if (key === "color") {
          return "#F87171"
        }
        return t("Rejected")
      case 3:
        if (key === "color") {
          return "#12B981"
        }
        return t("Approved")
      default:
        if (key === "color") {
          return "#F87171"
        }
    }
  }
  return (
    <Tag className="!rounded-[16px]" color={hexToRgba(getValue("color"), 0.1)}>
      <div className="flex gap-1 items-center">
        <div
          className="w-[6px] h-[6px] rounded-[3px]"
          style={{ backgroundColor: getValue("color") }}
        ></div>
        <div style={{ color: getValue("color") }}>{getValue("name")}</div>
      </div>
    </Tag>
  )
}
