import Client from "../client/Client"
import version from "../version"
const resource = version + "/client/groups"
const resourceModule = version + "/client/permissions"

function gets(params) {
  return Client.get(`${resource}/`, { params })
}
function getAll(params) {
  return Client.get(`${resource}/all/`, { params })
}
function get(id) {
  return Client.get(`${resource}/${id}/`)
}
function getModules(params) {
  return Client.get(`${resourceModule}/group-by/`, { params })
}
function create(data) {
  return Client.post(`${resource}/`, data)
}
function update(id, data) {
  return Client.put(`${resource}/${id}/`, data)
}
function remove(id) {
  return Client.delete(`${resource}/${id}/`)
}
function exportList(data) {
  return Client.post(`${resource}/export-csv/`, data)
}

const api = {
  gets,
  get,
  getModules,
  getAll,
  create,
  update,
  remove,
  exportList
}
export default api
