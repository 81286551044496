export const currency = {
  AF: {
    name: "Afghanistan",
    currency: "AFN",
    locale: "ps-AF",
    formatDateTime: "yyyy/MM/dd HH:mm"
  },
  AL: {
    name: "Albania",
    currency: "ALL",
    locale: "sq-AL",
    formatDateTime: "dd/MM/yyyy HH:mm"
  },
  DZ: {
    name: "Algeria",
    currency: "DZD",
    locale: "ar-DZ",
    formatDateTime: "dd/MM/yyyy HH:mm"
  },
  AS: {
    name: "American Samoa",
    currency: "USD",
    locale: "en-AS",
    formatDateTime: "MM/dd/yyyy hh:mm a"
  },
  AQ: {
    name: "Antarctica",
    currency: "USD",
    locale: "en-US",
    formatDateTime: "MM/dd/yyyy hh:mm a"
  },
  AR: {
    name: "Argentina",
    currency: "ARS",
    locale: "es-AR",
    formatDateTime: "dd/MM/yyyy HH:mm"
  },
  AM: {
    name: "Armenia",
    currency: "AMD",
    locale: "hy-AM",
    formatDateTime: "dd/MM/yyyy HH:mm"
  },
  AU: {
    name: "Australia",
    currency: "USD",
    locale: "en-AU",
    formatDateTime: "dd/MM/yyyy HH:mm"
  },
  AT: {
    name: "Austria",
    currency: "EUR",
    locale: "de-AT",
    formatDateTime: "dd.MM.yyyy HH:mm"
  },
  BH: {
    name: "Bahrain",
    currency: "BHD",
    locale: "ar-BH",
    formatDateTime: "dd/MM/yyyy HH:mm"
  },
  BD: {
    name: "Bangladesh",
    currency: "BDT",
    locale: "bn-BD",
    formatDateTime: "dd/MM/yyyy HH:mm"
  },
  BY: {
    name: "Belarus",
    currency: "BYN",
    locale: "be-BY",
    formatDateTime: "dd.MM.yyyy HH:mm"
  },
  BE: {
    name: "Belgium",
    currency: "EUR",
    locale: "nl-BE",
    formatDateTime: "dd/MM/yyyy HH:mm"
  },
  BZ: {
    name: "Belize",
    currency: "BZD",
    locale: "en-BZ",
    formatDateTime: "dd/MM/yyyy hh:mm"
  },
  BJ: {
    name: "Benin",
    currency: "XOF",
    locale: "fr-BJ",
    formatDateTime: "dd/MM/yyyy HH:mm"
  },
  BO: {
    name: "Bolivia, Plurinational State of",
    currency: "BTN",
    locale: "es-BO",
    formatDateTime: "dd/MM/yyyy HH:mm"
  },
  BA: {
    name: "Bosnia and Herzegovina",
    currency: "BAM",
    locale: "bs-BA",
    formatDateTime: "dd.MM.yyyy HH:mm"
  },
  BW: {
    name: "Botswana",
    currency: "BWP",
    locale: "en-BW",
    formatDateTime: "MM/dd/yyyy hh:mm a"
  },
  BR: {
    name: "Brazil",
    currency: "BRL",
    locale: "pt-BR",
    formatDateTime: "dd/MM/yyyy HH:mm"
  },
  BN: {
    name: "Brunei Darussalam",
    currency: "USD",
    locale: "ms-BN",
    formatDateTime: "dd/MM/yyyy HH:mm"
  },
  BG: {
    name: "Bulgaria",
    currency: "BGN",
    locale: "bg-BG",
    formatDateTime: "dd.MM.yyyy HH:mm"
  },
  BF: {
    name: "Burkina Faso",
    currency: "XOF",
    locale: "fr-BF",
    formatDateTime: "dd/MM/yyyy HH:mm"
  },
  BI: {
    name: "Burundi",
    currency: "BIF",
    locale: "fr-BI",
    formatDateTime: "dd/MM/yyyy HH:mm"
  },
  KH: {
    name: "Cambodia",
    currency: "KHR",
    locale: "km-KH",
    formatDateTime: "dd/MM/yyyy HH:mm"
  },
  CM: {
    name: "Cameroon",
    currency: "XAF",
    locale: "fr-CM",
    formatDateTime: "dd/MM/yyyy HH:mm"
  },
  CA: {
    name: "Canada",
    currency: "CAD",
    locale: "en-CA",
    formatDateTime: "MM/dd/yyyy hh:mm a"
  },
  CF: {
    name: "Central African Republic",
    currency: "USD",
    locale: "fr-CF",
    formatDateTime: "dd/MM/yyyy HH:mm"
  },
  TD: {
    name: "Chad",
    currency: "XAF",
    locale: "fr-TD",
    formatDateTime: "dd/MM/yyyy HH:mm"
  },
  CL: {
    name: "Chile",
    currency: "CLF",
    locale: "es-CL",
    formatDateTime: "dd/MM/yyyy HH:mm"
  },
  CO: {
    name: "Colombia",
    currency: "COU",
    locale: "es-CO",
    formatDateTime: "dd/MM/yyyy HH:mm"
  },
  KM: {
    name: "Comoros",
    currency: "USD",
    locale: "fr-KM",
    formatDateTime: "dd/MM/yyyy HH:mm"
  },
  CG: {
    name: "Congo",
    currency: "USD",
    locale: "fr-CG",
    formatDateTime: "dd/MM/yyyy HH:mm"
  },
  CD: {
    name: "Congo, The Democratic Republic of the Congo",
    currency: "USD",
    locale: "fr-CD",
    formatDateTime: "dd/MM/yyyy HH:mm"
  },
  CR: {
    name: "Costa Rica",
    currency: "CRC",
    locale: "es-CR",
    formatDateTime: "dd/MM/yyyy HH:mm"
  },
  CI: {
    name: "Cote d'Ivoire",
    currency: "CZK",
    locale: "fr-CI",
    formatDateTime: "dd/MM/yyyy HH:mm"
  },
  HR: {
    name: "Croatia",
    currency: "HRK",
    locale: "hr-HR",
    formatDateTime: "dd.MM.yyyy HH:mm"
  },
  CY: {
    name: "Cyprus",
    currency: "EUR",
    locale: "el-CY",
    formatDateTime: "dd/MM/yyyy HH:mm"
  },
  CZ: {
    name: "Czech Republic",
    currency: "USD",
    locale: "cs-CZ",
    formatDateTime: "dd/MM/yyyy HH:mm"
  },
  DK: {
    name: "Denmark",
    currency: "DKK",
    locale: "da-DK",
    formatDateTime: "dd/MM/yyyy HH:mm"
  },
  DJ: {
    name: "Djibouti",
    currency: "DJF",
    locale: "fr-DJ",
    formatDateTime: "dd/MM/yyyy HH:mm"
  },
  DO: {
    name: "Dominican Republic",
    currency: "DOP",
    locale: "es-DO",
    formatDateTime: "dd/MM/yyyy HH:mm"
  },
  EC: {
    name: "Ecuador",
    currency: "USD",
    locale: "es-EC",
    formatDateTime: "dd/MM/yyyy HH:mm"
  },
  EG: {
    name: "Egypt",
    currency: "EGP",
    locale: "ar-EG",
    formatDateTime: "dd/MM/yyyy HH:mm"
  },
  SV: {
    name: "El Salvador",
    currency: "USD",
    locale: "es-SV",
    formatDateTime: "dd/MM/yyyy HH:mm"
  },
  GQ: {
    name: "Equatorial Guinea",
    currency: "XAF",
    locale: "fr-GQ",
    formatDateTime: "dd/MM/yyyy HH:mm"
  },
  ER: {
    name: "Eritrea",
    currency: "ERN",
    locale: "ti-ER",
    formatDateTime: "dd/MM/yyyy HH:mm"
  },
  EE: {
    name: "Estonia",
    currency: "EUR",
    locale: "et-EE",
    formatDateTime: "dd.MM.yyyy HH:mm"
  },
  ET: {
    name: "Ethiopia",
    currency: "ETB",
    locale: "am-ET",
    formatDateTime: "dd/MM/yyyy HH:mm"
  },
  FJ: {
    name: "Fiji",
    currency: "FJD",
    locale: "en-FJ",
    formatDateTime: "dd/MM/yyyy HH:mm"
  },
  FI: {
    name: "Finland",
    currency: "EUR",
    locale: "fi-FI",
    formatDateTime: "dd.MM.yyyy HH:mm"
  },
  FR: {
    name: "France",
    currency: "EUR",
    locale: "fr-FR",
    formatDateTime: "dd/MM/yyyy HH:mm"
  },
  GA: {
    name: "Gabon",
    currency: "XAF",
    locale: "fr-GA",
    formatDateTime: "dd/MM/yyyy HH:mm"
  },
  GM: {
    name: "Gambia",
    currency: "GMD",
    locale: "en-GM",
    formatDateTime: "dd/MM/yyyy HH:mm"
  },
  GE: {
    name: "Georgia",
    currency: "GEL",
    locale: "ka-GE",
    formatDateTime: "dd/MM/yyyy HH:mm"
  },
  DE: {
    name: "Germany",
    currency: "EUR",
    locale: "de-DE",
    formatDateTime: "dd.MM.yyyy HH:mm"
  },
  GH: {
    name: "Ghana",
    currency: "GHS",
    locale: "en-GH",
    formatDateTime: "dd/MM/yyyy HH:mm"
  },
  GI: {
    name: "Gibraltar",
    currency: "GIP",
    locale: "en-GI",
    formatDateTime: "dd/MM/yyyy HH:mm"
  },
  GR: {
    name: "Greece",
    currency: "EUR",
    locale: "el-GR",
    formatDateTime: "dd/MM/yyyy HH:mm"
  },
  GL: {
    name: "Greenland",
    currency: "DKK",
    locale: "kl-GL",
    formatDateTime: "dd/MM/yyyy HH:mm"
  },
  GT: {
    name: "Guatemala",
    currency: "GTQ",
    locale: "es-GT",
    formatDateTime: "dd/MM/yyyy HH:mm"
  },
  GN: {
    name: "Guinea",
    currency: "GNF",
    locale: "fr-GN",
    formatDateTime: "dd/MM/yyyy HH:mm"
  },
  GW: {
    name: "Guinea-Bissau",
    currency: "XOF",
    locale: "pt-GW",
    formatDateTime: "dd/MM/yyyy HH:mm"
  },
  GY: {
    name: "Guyana",
    currency: "GYD",
    locale: "en-GY",
    formatDateTime: "dd/MM/yyyy HH:mm"
  },
  HT: {
    name: "Haiti",
    currency: "HTG",
    locale: "fr-HT",
    formatDateTime: "dd/MM/yyyy HH:mm"
  },
  HN: {
    name: "Honduras",
    currency: "HNL",
    locale: "es-HN",
    formatDateTime: "dd/MM/yyyy HH:mm"
  },
  HK: {
    name: "Hong Kong",
    currency: "HKD",
    locale: "zh-HK",
    formatDateTime: "dd/MM/yyyy HH:mm"
  },
  HU: {
    name: "Hungary",
    currency: "HUF",
    locale: "hu-HU",
    formatDateTime: "yyyy.MM.dd HH:mm"
  },
  IS: {
    name: "Iceland",
    currency: "ISK",
    locale: "is-IS",
    formatDateTime: "dd.MM.yyyy HH:mm"
  },
  IN: {
    name: "India",
    currency: "INR",
    locale: "hi-IN",
    formatDateTime: "dd/MM/yyyy HH:mm"
  },
  ID: {
    name: "Indonesia",
    currency: "IDR",
    locale: "id-ID",
    formatDateTime: "dd/MM/yyyy HH:mm"
  },
  IR: {
    name: "Iran, Islamic Republic of",
    currency: "IRR",
    locale: "fa-IR",
    formatDateTime: "yyyy/MM/dd HH:mm"
  },
  IQ: {
    name: "Iraq",
    currency: "IQD",
    locale: "ar-IQ",
    formatDateTime: "dd/MM/yyyy HH:mm"
  },
  IE: {
    name: "Ireland",
    currency: "EUR",
    locale: "en-IE",
    formatDateTime: "dd/MM/yyyy HH:mm"
  },
  IL: {
    name: "Israel",
    currency: "ILS",
    locale: "he-IL",
    formatDateTime: "dd/MM/yyyy HH:mm"
  },
  IT: {
    name: "Italy",
    currency: "EUR",
    locale: "it-IT",
    formatDateTime: "dd/MM/yyyy HH:mm"
  },
  JM: {
    name: "Jamaica",
    currency: "JMD",
    locale: "en-JM",
    formatDateTime: "dd/MM/yyyy HH:mm"
  },
  JP: {
    name: "Japan",
    currency: "JPY",
    locale: "ja-JP",
    formatDateTime: "yyyy/MM/dd HH:mm"
  },
  JO: {
    name: "Jordan",
    currency: "JOD",
    locale: "ar-JO",
    formatDateTime: "dd/MM/yyyy HH:mm"
  },
  KZ: {
    name: "Kazakhstan",
    currency: "KZT",
    locale: "kk-KZ",
    formatDateTime: "dd.MM.yyyy HH:mm"
  },
  KE: {
    name: "Kenya",
    currency: "KES",
    locale: "en-KE",
    formatDateTime: "dd/MM/yyyy HH:mm"
  },
  KI: {
    name: "Kiribati",
    currency: "AUD",
    locale: "en-KI",
    formatDateTime: "dd/MM/yyyy HH:mm"
  },
  KP: {
    name: "Korea, Democratic People's Republic of",
    currency: "KPW",
    locale: "ko-KP",
    formatDateTime: "yyyy.MM.dd HH:mm"
  },
  KR: {
    name: "Korea, Republic of",
    currency: "KRW",
    locale: "ko-KR",
    formatDateTime: "yyyy/MM/dd HH:mm"
  },
  KW: {
    name: "Kuwait",
    currency: "KWD",
    locale: "ar-KW",
    formatDateTime: "dd/MM/yyyy HH:mm"
  },
  KG: {
    name: "Kyrgyzstan",
    currency: "KGS",
    locale: "ky-KG",
    formatDateTime: "dd.MM.yyyy HH:mm"
  },
  LA: {
    name: "Lao People's Democratic Republic",
    currency: "LAK",
    locale: "lo-LA",
    formatDateTime: "dd/MM/yyyy HH:mm"
  },
  LV: {
    name: "Latvia",
    currency: "EUR",
    locale: "lv-LV",
    formatDateTime: "dd.MM.yyyy HH:mm"
  },
  LB: {
    name: "Lebanon",
    currency: "LBP",
    locale: "ar-LB",
    formatDateTime: "dd/MM/yyyy HH:mm"
  },
  LS: {
    name: "Lesotho",
    currency: "LSL",
    locale: "en-LS",
    formatDateTime: "dd/MM/yyyy HH:mm"
  },
  LR: {
    name: "Liberia",
    currency: "LRD",
    locale: "en-LR",
    formatDateTime: "dd/MM/yyyy HH:mm"
  },
  LY: {
    name: "Libya",
    currency: "LYD",
    locale: "ar-LY",
    formatDateTime: "dd/MM/yyyy HH:mm"
  },
  LI: {
    name: "Liechtenstein",
    currency: "CHF",
    locale: "de-LI",
    formatDateTime: "dd.MM.yyyy HH:mm"
  },
  LT: {
    name: "Lithuania",
    currency: "EUR",
    locale: "lt-LT",
    formatDateTime: "dd.MM.yyyy HH:mm"
  },
  LU: {
    name: "Luxembourg",
    currency: "EUR",
    locale: "fr-LU",
    formatDateTime: "dd/MM/yyyy HH:mm"
  },
  MO: {
    name: "Macao",
    currency: "MOP",
    locale: "zh-MO",
    formatDateTime: "dd/MM/yyyy HH:mm"
  },
  MK: {
    name: "North Macedonia",
    currency: "MKD",
    locale: "mk-MK",
    formatDateTime: "dd.MM.yyyy HH:mm"
  },
  MG: {
    name: "Madagascar",
    currency: "MGA",
    locale: "fr-MG",
    formatDateTime: "dd/MM/yyyy HH:mm"
  },
  MW: {
    name: "Malawi",
    currency: "MWK",
    locale: "en-MW",
    formatDateTime: "dd/MM/yyyy HH:mm"
  },
  MY: {
    name: "Malaysia",
    currency: "MYR",
    locale: "ms-MY",
    formatDateTime: "dd/MM/yyyy HH:mm"
  },
  MV: {
    name: "Maldives",
    currency: "MVR",
    locale: "dv-MV",
    formatDateTime: "dd/MM/yyyy HH:mm"
  },
  ML: {
    name: "Mali",
    currency: "XOF",
    locale: "fr-ML",
    formatDateTime: "dd/MM/yyyy HH:mm"
  },
  MT: {
    name: "Malta",
    currency: "EUR",
    locale: "mt-MT",
    formatDateTime: "dd/MM/yyyy HH:mm"
  },
  MH: {
    name: "Marshall Islands",
    currency: "USD",
    locale: "en-MH",
    formatDateTime: "MM/dd/yyyy HH:mm"
  },
  MR: {
    name: "Mauritania",
    currency: "MRU",
    locale: "ar-MR",
    formatDateTime: "dd/MM/yyyy HH:mm"
  },
  MU: {
    name: "Mauritius",
    currency: "MUR",
    locale: "en-MU",
    formatDateTime: "dd/MM/yyyy HH:mm"
  },
  YT: {
    name: "Mayotte",
    currency: "EUR",
    locale: "fr-YT",
    formatDateTime: "dd/MM/yyyy HH:mm"
  },
  MX: {
    name: "Mexico",
    currency: "MXN",
    locale: "es-MX",
    formatDateTime: "dd/MM/yyyy HH:mm"
  },
  FM: {
    name: "Micronesia (Federated States of)",
    currency: "USD",
    locale: "en-FM",
    formatDateTime: "MM/dd/yyyy HH:mm"
  },
  MD: {
    name: "Moldova, Republic of",
    currency: "MDL",
    locale: "ro-MD",
    formatDateTime: "dd.MM.yyyy HH:mm"
  },
  MC: {
    name: "Monaco",
    currency: "EUR",
    locale: "fr-MC",
    formatDateTime: "dd/MM/yyyy HH:mm"
  },
  MN: {
    name: "Mongolia",
    currency: "MNT",
    locale: "mn-MN",
    formatDateTime: "dd/MM/yyyy HH:mm"
  },
  ME: {
    name: "Montenegro",
    currency: "EUR",
    locale: "sr-ME",
    formatDateTime: "dd.MM.yyyy HH:mm"
  },
  MS: {
    name: "Montserrat",
    currency: "XCD",
    locale: "en-MS",
    formatDateTime: "dd/MM/yyyy HH:mm"
  },
  MA: {
    name: "Morocco",
    currency: "MAD",
    locale: "ar-MA",
    formatDateTime: "dd/MM/yyyy HH:mm"
  },
  MZ: {
    name: "Mozambique",
    currency: "MZN",
    locale: "pt-MZ",
    formatDateTime: "dd/MM/yyyy HH:mm"
  },
  MM: {
    name: "Myanmar",
    currency: "MMK",
    locale: "my-MM",
    formatDateTime: "dd/MM/yyyy HH:mm"
  },
  NA: {
    name: "Namibia",
    currency: "NAD",
    locale: "en-NA",
    formatDateTime: "dd/MM/yyyy HH:mm"
  },
  NR: {
    name: "Nauru",
    currency: "AUD",
    locale: "en-NR",
    formatDateTime: "dd/MM/yyyy HH:mm"
  },
  NP: {
    name: "Nepal",
    currency: "NPR",
    locale: "ne-NP",
    formatDateTime: "yy/MM/dd HH:mm"
  },
  NL: {
    name: "Netherlands",
    currency: "EUR",
    locale: "nl-NL",
    formatDateTime: "dd-MM-yyyy HH:mm"
  },
  NC: {
    name: "New Caledonia",
    currency: "XPF",
    locale: "fr-NC",
    formatDateTime: "dd/MM/yyyy HH:mm"
  },
  NZ: {
    name: "New Zealand",
    currency: "NZD",
    locale: "en-NZ",
    formatDateTime: "dd/MM/yyyy HH:mm"
  },
  NI: {
    name: "Nicaragua",
    currency: "NIO",
    locale: "es-NI",
    formatDateTime: "dd/MM/yyyy HH:mm"
  },
  NE: {
    name: "Niger",
    currency: "XOF",
    locale: "fr-NE",
    formatDateTime: "dd/MM/yyyy HH:mm"
  },
  NG: {
    name: "Nigeria",
    currency: "NGN",
    locale: "en-NG",
    formatDateTime: "dd/MM/yyyy HH:mm"
  },
  NU: {
    name: "Niue",
    currency: "NZD",
    locale: "en-NU",
    formatDateTime: "dd/MM/yyyy HH:mm"
  },
  NF: {
    name: "Norfolk Island",
    currency: "AUD",
    locale: "en-NF",
    formatDateTime: "dd/MM/yyyy HH:mm"
  },
  MP: {
    name: "Northern Mariana Islands",
    currency: "USD",
    locale: "en-MP",
    formatDateTime: "MM/dd/yyyy HH:mm"
  },
  NO: {
    name: "Norway",
    currency: "NOK",
    locale: "no-NO",
    formatDateTime: "dd.MM.yyyy HH:mm"
  },
  OM: {
    name: "Oman",
    currency: "OMR",
    locale: "ar-OM",
    formatDateTime: "dd/MM/yyyy HH:mm"
  },
  PK: {
    name: "Pakistan",
    currency: "PKR",
    locale: "ur-PK",
    formatDateTime: "dd/MM/yyyy HH:mm"
  },
  PW: {
    name: "Palau",
    currency: "USD",
    locale: "en-PW",
    formatDateTime: "MM/dd/yyyy HH:mm"
  },
  PA: {
    name: "Panama",
    currency: "PAB",
    locale: "es-PA",
    formatDateTime: "dd/MM/yyyy HH:mm"
  },
  PG: {
    name: "Papua New Guinea",
    currency: "PGK",
    locale: "en-PG",
    formatDateTime: "dd/MM/yyyy HH:mm"
  },
  PY: {
    name: "Paraguay",
    currency: "PYG",
    locale: "es-PY",
    formatDateTime: "dd/MM/yyyy HH:mm"
  },
  PE: {
    name: "Peru",
    currency: "PEN",
    locale: "es-PE",
    formatDateTime: "dd/MM/yyyy HH:mm"
  },
  PH: {
    name: "Philippines",
    currency: "PHP",
    locale: "en-PH",
    formatDateTime: "dd/MM/yyyy HH:mm"
  },
  PN: {
    name: "Pitcairn",
    currency: "NZD",
    locale: "en-PN",
    formatDateTime: "dd/MM/yyyy HH:mm"
  },
  PL: {
    name: "Poland",
    currency: "PLN",
    locale: "pl-PL",
    formatDateTime: "dd.MM.yyyy HH:mm"
  },
  PT: {
    name: "Portugal",
    currency: "EUR",
    locale: "pt-PT",
    formatDateTime: "dd/MM/yyyy HH:mm"
  },
  PR: {
    name: "Puerto Rico",
    currency: "USD",
    locale: "en-PR",
    formatDateTime: "MM/dd/yyyy HH:mm"
  },
  QA: {
    name: "Qatar",
    currency: "QAR",
    locale: "ar-QA",
    formatDateTime: "dd/MM/yyyy HH:mm"
  },
  RE: {
    name: "Réunion",
    currency: "EUR",
    locale: "fr-RE",
    formatDateTime: "dd/MM/yyyy HH:mm"
  },
  RO: {
    name: "Romania",
    currency: "RON",
    locale: "ro-RO",
    formatDateTime: "dd.MM.yyyy HH:mm"
  },
  RU: {
    name: "Russian Federation",
    currency: "RUB",
    locale: "ru-RU",
    formatDateTime: "dd.MM.yyyy HH:mm"
  },
  RW: {
    name: "Rwanda",
    currency: "RWF",
    locale: "rw-RW",
    formatDateTime: "dd/MM/yyyy HH:mm"
  },
  BL: {
    name: "Saint Barthélemy",
    currency: "EUR",
    locale: "fr-BL",
    formatDateTime: "dd/MM/yyyy HH:mm"
  },
  SH: {
    name: "Saint Helena",
    currency: "SHP",
    locale: "en-SH",
    formatDateTime: "dd/MM/yyyy HH:mm"
  },
  KN: {
    name: "Saint Kitts and Nevis",
    currency: "XCD",
    locale: "en-KN",
    formatDateTime: "dd/MM/yyyy HH:mm"
  },
  LC: {
    name: "Saint Lucia",
    currency: "XCD",
    locale: "en-LC",
    formatDateTime: "dd/MM/yyyy HH:mm"
  },
  MF: {
    name: "Saint Martin (French part)",
    currency: "EUR",
    locale: "fr-MF",
    formatDateTime: "dd/MM/yyyy HH:mm"
  },
  PM: {
    name: "Saint Pierre and Miquelon",
    currency: "EUR",
    locale: "fr-PM",
    formatDateTime: "dd/MM/yyyy HH:mm"
  },
  VC: {
    name: "Saint Vincent and the Grenadines",
    currency: "XCD",
    locale: "en-VC",
    formatDateTime: "dd/MM/yyyy HH:mm"
  },
  WS: {
    name: "Samoa",
    currency: "WST",
    locale: "en-WS",
    formatDateTime: "dd/MM/yyyy HH:mm"
  },
  SM: {
    name: "San Marino",
    currency: "EUR",
    locale: "it-SM",
    formatDateTime: "dd/MM/yyyy HH:mm"
  },
  ST: {
    name: "Sao Tome and Principe",
    currency: "STN",
    locale: "pt-ST",
    formatDateTime: "dd/MM/yyyy HH:mm"
  },
  SA: {
    name: "Saudi Arabia",
    currency: "SAR",
    locale: "ar-SA",
    formatDateTime: "dd/MM/yyyy HH:mm"
  },
  SN: {
    name: "Senegal",
    currency: "XOF",
    locale: "fr-SN",
    formatDateTime: "dd/MM/yyyy HH:mm"
  },
  RS: {
    name: "Serbia",
    currency: "RSD",
    locale: "sr-RS",
    formatDateTime: "dd.MM.yyyy HH:mm"
  },
  SC: {
    name: "Seychelles",
    currency: "SCR",
    locale: "en-SC",
    formatDateTime: "dd/MM/yyyy HH:mm"
  },
  SL: {
    name: "Sierra Leone",
    currency: "SLL",
    locale: "en-SL",
    formatDateTime: "dd/MM/yyyy HH:mm"
  },
  SG: {
    name: "Singapore",
    currency: "SGD",
    locale: "en-SG",
    formatDateTime: "dd/MM/yyyy HH:mm"
  },
  SX: {
    name: "Sint Maarten (Dutch part)",
    currency: "ANG",
    locale: "en-SX",
    formatDateTime: "dd/MM/yyyy HH:mm"
  },
  SK: {
    name: "Slovakia",
    currency: "EUR",
    locale: "sk-SK",
    formatDateTime: "dd.MM.yyyy HH:mm"
  },
  SI: {
    name: "Slovenia",
    currency: "EUR",
    locale: "sl-SI",
    formatDateTime: "dd.MM.yyyy HH:mm"
  },
  SB: {
    name: "Solomon Islands",
    currency: "SBD",
    locale: "en-SB",
    formatDateTime: "dd/MM/yyyy HH:mm"
  },
  SO: {
    name: "Somalia",
    currency: "SOS",
    locale: "so-SO",
    formatDateTime: "dd/MM/yyyy HH:mm"
  },
  ZA: {
    name: "South Africa",
    currency: "ZAR",
    locale: "en-ZA",
    formatDateTime: "yyyy/MM/dd HH:mm"
  },
  GS: {
    name: "South Georgia and the South Sandwich Islands",
    currency: "GBP",
    locale: "en-GS",
    formatDateTime: "dd/MM/yyyy HH:mm"
  },
  SS: {
    name: "South Sudan",
    currency: "SSP",
    locale: "en-SS",
    formatDateTime: "dd/MM/yyyy HH:mm"
  },
  ES: {
    name: "Spain",
    currency: "EUR",
    locale: "es-ES",
    formatDateTime: "dd/MM/yyyy HH:mm"
  },
  LK: {
    name: "Sri Lanka",
    currency: "LKR",
    locale: "si-LK",
    formatDateTime: "yyyy/MM/dd HH:mm"
  },
  SD: {
    name: "Sudan",
    currency: "SDG",
    locale: "ar-SD",
    formatDateTime: "dd/MM/yyyy HH:mm"
  },
  SR: {
    name: "Suriname",
    currency: "SRD",
    locale: "nl-SR",
    formatDateTime: "dd/MM/yyyy HH:mm"
  },
  SZ: {
    name: "Eswatini",
    currency: "SZL",
    locale: "en-SZ",
    formatDateTime: "dd/MM/yyyy HH:mm"
  },
  SE: {
    name: "Sweden",
    currency: "SEK",
    locale: "sv-SE",
    formatDateTime: "yyyy-MM-dd HH:mm"
  },
  CH: {
    name: "Switzerland",
    currency: "CHF",
    locale: "de-CH",
    formatDateTime: "dd.MM.yyyy HH:mm"
  },
  SY: {
    name: "Syrian Arab Republic",
    currency: "SYP",
    locale: "ar-SY",
    formatDateTime: "dd/MM/yyyy HH:mm"
  },
  TW: {
    name: "Taiwan, Province of China",
    currency: "TWD",
    locale: "zh-TW",
    formatDateTime: "yyyy/MM/dd HH:mm"
  },
  TJ: {
    name: "Tajikistan",
    currency: "TJS",
    locale: "tg-TJ",
    formatDateTime: "dd/MM/yyyy HH:mm"
  },
  TZ: {
    name: "Tanzania, United Republic of",
    currency: "TZS",
    locale: "sw-TZ",
    formatDateTime: "dd/MM/yyyy HH:mm"
  },
  TH: {
    name: "Thailand",
    currency: "THB",
    locale: "th-TH",
    formatDateTime: "dd/MM/yyyy HH:mm"
  },
  TL: {
    name: "Timor-Leste",
    currency: "USD",
    locale: "pt-TL",
    formatDateTime: "dd/MM/yyyy HH:mm"
  },
  TG: {
    name: "Togo",
    currency: "XOF",
    locale: "fr-TG",
    formatDateTime: "dd/MM/yyyy HH:mm"
  },
  TK: {
    name: "Tokelau",
    currency: "NZD",
    locale: "en-TK",
    formatDateTime: "dd/MM/yyyy HH:mm"
  },
  TO: {
    name: "Tonga",
    currency: "TOP",
    locale: "en-TO",
    formatDateTime: "dd/MM/yyyy HH:mm"
  },
  TT: {
    name: "Trinidad and Tobago",
    currency: "TTD",
    locale: "en-TT",
    formatDateTime: "dd/MM/yyyy HH:mm"
  },
  TN: {
    name: "Tunisia",
    currency: "TND",
    locale: "ar-TN",
    formatDateTime: "dd/MM/yyyy HH:mm"
  },
  TR: {
    name: "Turkey",
    currency: "TRY",
    locale: "tr-TR",
    formatDateTime: "dd.MM.yyyy HH:mm"
  },
  TM: {
    name: "Turkmenistan",
    currency: "TMT",
    locale: "tk-TM",
    formatDateTime: "dd.MM.yyyy HH:mm"
  },
  TV: {
    name: "Tuvalu",
    currency: "AUD",
    locale: "en-TV",
    formatDateTime: "dd/MM/yyyy HH:mm"
  },
  UG: {
    name: "Uganda",
    currency: "UGX",
    locale: "en-UG",
    formatDateTime: "dd/MM/yyyy HH:mm"
  },
  UA: {
    name: "Ukraine",
    currency: "UAH",
    locale: "uk-UA",
    formatDateTime: "dd.MM.yyyy HH:mm"
  },
  AE: {
    name: "United Arab Emirates",
    currency: "AED",
    locale: "ar-AE",
    formatDateTime: "dd/MM/yyyy HH:mm"
  },
  GB: {
    name: "United Kingdom",
    currency: "GBP",
    locale: "en-GB",
    formatDateTime: "dd/MM/yyyy HH:mm"
  },
  US: {
    name: "United States",
    currency: "USD",
    locale: "en-US",
    formatDateTime: "MM/dd/yyyy hh:mm a"
  },
  UY: {
    name: "Uruguay",
    currency: "UYU",
    locale: "es-UY",
    formatDateTime: "dd/MM/yyyy HH:mm"
  },
  UZ: {
    name: "Uzbekistan",
    currency: "UZS",
    locale: "uz-UZ",
    formatDateTime: "dd.MM.yyyy HH:mm"
  },
  VU: {
    name: "Vanuatu",
    currency: "VUV",
    locale: "en-VU",
    formatDateTime: "dd/MM/yyyy HH:mm"
  },
  VE: {
    name: "Venezuela (Bolivarian Republic of)",
    currency: "VES",
    locale: "es-VE",
    formatDateTime: "dd/MM/yyyy HH:mm"
  },
  VN: {
    name: "Viet Nam",
    currency: "VND",
    locale: "vi-VN",
    formatDateTime: "HH:mm dd/MM/yyyy"
  },
  WF: {
    name: "Wallis and Futuna",
    currency: "XPF",
    locale: "fr-WF",
    formatDateTime: "dd/MM/yyyy HH:mm"
  },
  EH: {
    name: "Western Sahara",
    currency: "MAD",
    locale: "ar-EH",
    formatDateTime: "dd/MM/yyyy HH:mm"
  },
  YE: {
    name: "Yemen",
    currency: "YER",
    locale: "ar-YE",
    formatDateTime: "dd/MM/yyyy HH:mm"
  },
  ZM: {
    name: "Zambia",
    currency: "ZMW",
    locale: "en-ZM",
    formatDateTime: "dd/MM/yyyy HH:mm"
  },
  ZW: {
    name: "Zimbabwe",
    currency: "ZWL",
    locale: "en-ZW",
    formatDateTime: "dd/MM/yyyy HH:mm"
  }
}
