import { RESOURCE_KEY } from "lib/Const"
import { lazy } from "react"

const Dashboard = lazy(() => import("pages/Dashboard"))
const UserProfile = lazy(() => import("pages/UserProfile"))
const BrandManagement = lazy(() => import("pages/BrandManagement"))
const BillingManagement = lazy(() => import("pages/BillingManagement"))
// const BillingDetail = lazy(() => import("pages/Billing/BillingDetail"))
const UpdateBrandManagement = lazy(() => import("pages/BrandManagement/UpdateBrandManagement"))
const ViewBrandManagement = lazy(() => import("pages/BrandManagement/ViewBrandManagement"))

const RoleAndPermissionSetting = lazy(() => import("pages/UserManagement/RoleAndPermissionSetting"))
const DetailPermissonSetting = lazy(
  () => import("pages/UserManagement/RoleAndPermissionSetting/DetailPermissonSetting")
)
const UserList = lazy(() => import("pages/UserManagement/UserList"))
const UpdateUser = lazy(() => import("pages/UserManagement/UserList/UpdateUser"))
const Domain = lazy(() => import("pages/Domain"))
const SubDomain = lazy(() => import("pages/SubDomain"))
const ApprovalList = lazy(() => import("pages/ApprovalList"))
const ActivityLog = lazy(() => import("pages/ActivityLog"))
const SendNotification = lazy(() => import("pages/SendNotification"))
const Segment = lazy(() => import("pages/Segment"))
const MyNotification = lazy(() => import("pages/MyNotification"))

// Settings
const ProfileSetting = lazy(() => import("pages/Settings/GeneralSetting/ProfileSetting"))
const ApplicationSetting = lazy(() => import("pages/Settings/ApplicationSetting"))
const SecuritySetting = lazy(() => import("pages/Settings/SecuritySetting"))
const ApprovalSetting = lazy(() => import("pages/Settings/ApprovalSetting"))
const UpdateApprovalSetting = lazy(() => import("pages/Settings/ApprovalSetting/UpdateApproval"))
const SystemMaintenance = lazy(() => import("pages/Settings/SystemMaintenance"))
const ThemeSetting = lazy(() => import("pages/Settings/ThemeSetting"))
const DomainSetting = lazy(() => import("pages/Settings/CustomDomainSetting"))

const SupportTicket = lazy(() => import("pages/SupportTicket"))
const ViewSupportTicket = lazy(() => import("pages/SupportTicket/ViewSupportTicket"))

//____ListPage
function pageList(__role) {
  return [
    {
      path: "/",
      Element: Dashboard,
      resource: RESOURCE_KEY.DASHBOARD
    },
    {
      path: "dashboard",
      pageName: "Dashboard",
      Element: Dashboard,
      resource: RESOURCE_KEY.DASHBOARD
    },
    {
      path: "/profile",
      Element: UserProfile,
      resource: RESOURCE_KEY.USER_PROFILE
    },
    {
      path: "/brand",
      Element: BrandManagement,
      resource: RESOURCE_KEY.BRAND
    },
    {
      path: "/billing",
      Element: BillingManagement,
      resource: RESOURCE_KEY.BILLING
    },
    {
      path: "brand/action",
      Element: UpdateBrandManagement,
      resource: RESOURCE_KEY.BRAND
    },
    {
      path: "brand/action/view",
      Element: ViewBrandManagement,
      resource: RESOURCE_KEY.BRAND
    },

    {
      path: "/user/roles",
      Element: RoleAndPermissionSetting,
      resource: RESOURCE_KEY.USER_ROLE
    },
    {
      path: "/user/roles/action/view",
      Element: DetailPermissonSetting,
      resource: RESOURCE_KEY.USER_ROLE
    },
    {
      path: "/user/users",
      Element: UserList,
      resource: RESOURCE_KEY.USER_USERS
    },
    {
      path: "/user/users/action",
      Element: UpdateUser,
      resource: RESOURCE_KEY.USER_USERS
    },
    {
      path: "/domain-setting/list-domain",
      Element: Domain,
      resource: RESOURCE_KEY.DOMAIN
    },
    {
      path: "/domain-setting/list-sub-domain",
      Element: SubDomain,
      resource: RESOURCE_KEY.DOMAIN
    },
    {
      path: "/approval",
      Element: ApprovalList,
      resource: RESOURCE_KEY.APPROVAL
    },
    {
      path: "activity-logs",
      Element: ActivityLog,
      resource: RESOURCE_KEY.ACTIVITY_LOG
    },
    {
      path: "/notification/send-notification",
      Element: SendNotification,
      resource: RESOURCE_KEY.NOTIFICATION_LIST
    },
    {
      path: "/notification/segment",
      Element: Segment,
      resource: RESOURCE_KEY.NOTIFICATION_SEMENT
    },
    {
      path: "/my-notification",
      Element: MyNotification,
      resource: RESOURCE_KEY.MY_NOTIFICATION
    },
    // Settings
    {
      path: "/setting/general",
      Element: ProfileSetting,
      resource: RESOURCE_KEY.SETTING_GENERAL
    },
    {
      path: "/setting/application-setting",
      Element: ApplicationSetting,
      resource: RESOURCE_KEY.SETTING_APPLICATION
    },
    {
      path: "/setting/security-setting",
      Element: SecuritySetting,
      resource: RESOURCE_KEY.SETTING_SECURITY
    },
    {
      path: "/setting/system-maintenance",
      Element: SystemMaintenance,
      resource: RESOURCE_KEY.SETTING_SYSTEM
    },
    {
      path: "/setting/theme-setting",
      Element: ThemeSetting,
      resource: RESOURCE_KEY.SETTING_THEME
    },
    {
      path: "/setting/custom-domain-setting",
      Element: DomainSetting,
      resource: RESOURCE_KEY.SETTING_DOMAIN
    },
    {
      path: "/setting/appr-setting",
      Element: ApprovalSetting,
      resource: RESOURCE_KEY.SETTING_APPROVAL
    },
    {
      path: "/setting/appr-setting/action",
      Element: UpdateApprovalSetting,
      resource: RESOURCE_KEY.SETTING_APPROVAL
    },
    {
      path: "/support-ticket",
      Element: SupportTicket,
      resource: RESOURCE_KEY.SUPPORT_TICKET
    },
    {
      path: "/support-ticket/action",
      Element: ViewSupportTicket,
      resource: RESOURCE_KEY.SUPPORT_TICKET
    }
    // {
    //   path: "billing/action/view",
    //   Element: BillingDetail,
    //   resource: RESOURCE_KEY.ROOT_SERVICE
    // }
  ]
}

export default pageList
