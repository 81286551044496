import { useEffect, useState } from "react"
import { Modal, Button, Form, Input, message } from "antd"
import LabelInput from "components/ui/LabelInput"
import QRCode from "react-qr-code"
import copy from "copy-to-clipboard"
import axios from "axios"
import AlertError from "components/AlertError"
import { useTranslation } from "react-i18next"

const baseDomain = process.env.REACT_APP_ID_URL

export default function ModalVerifyCode({ visible, user, setUser, onClose, onSubmit }) {
  const { t } = useTranslation()
  const [form] = Form.useForm()
  const [loading, setLoading] = useState(false)
  const [userData, setUserData] = useState({})
  const [isSubmited, setIsSubmited] = useState(false)
  const [error, setError] = useState("")

  async function fetch() {
    setLoading(true)
    axios({
      url: `${baseDomain}/api/v1/google-auth/update-secret-key/${user?.id}/`,
      data: { two_fa: 2 },
      method: "put",
      headers: {
        // Authorization: `Bearer ${access_token}`,
        "Content-Type": "application/json"
      }
    })
      .then((response) => {
        setLoading(false)
        const { data } = response.data
        setUserData(data)
        setUser(data)
      })
      .catch((err) => {
        setLoading(false)
        console.log(err)
      })
  }

  function onCopy() {
    message.success({ content: t("Copied!") })
    copy(userData?.secret_key, {
      debug: true,
      message: "Copied!"
    })
  }

  function onChange() {
    setError("")
    setIsSubmited(true)
  }

  const onFinish = async (values) => {
    setError("")
    setLoading(true)
    axios({
      url: `${baseDomain}/api/v1/google-auth/validate/`,
      data: { code: values.code, user_id: userData?.id },
      method: "post",
      headers: {
        // Authorization: `Bearer ${access_token}`,
        "Content-Type": "application/json"
      }
    })
      .then((response) => {
        setLoading(false)
        const { data } = response.data
        onSubmit(data?.token)
      })
      .catch((err) => {
        setLoading(false)
        console.log(err)
        const { data } = err?.response
        setError(data?.error_message[0] || "Invalid code")
      })
  }

  useEffect(() => {
    if (visible && user) {
      if (user?.secret_key !== "") {
        setUserData(user)
      } else {
        fetch()
      }
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [visible])

  return (
    <Modal
      title={t("Setup Authenticator App")}
      centered
      width={720}
      open={visible}
      onCancel={onClose}
      onOk={onSubmit}
      footer={[
        <Button key="back" onClick={onClose}>
          {t("Cancel")}
        </Button>,
        <Button
          key="submit"
          type="primary"
          loading={loading}
          onClick={() => form.submit()}
          disabled={!isSubmited}
        >
          {t("Continue")}
        </Button>
      ]}
    >
      <div>
        <div className="my-3 text-[#646970]">
          {t(
            "Authenticator apps and browser extensions like 1Password, Authy, Microsoft Authenticator, etc. generateone-time passwords that are used as a second factor to verify your identity when prompted during sign-in"
          )}
        </div>
        <div className="font-bold">{t("Scan the QR code")}</div>
        <div className="text-[#646970]">
          {t("Use an authenticator app or browser extension to scan.")}{" "}
          {/* eslint-disable-next-line jsx-a11y/anchor-is-valid */}
          <a>{t("Learn more about enabling 2FA")}</a>
        </div>
        <div className="mt-5">
          <QRCode
            size={240}
            value={
              userData?.secret_key
                ? `otpauth://totp/netko-solution:${userData?.email}?secret=${userData?.secret_key}&issuer=netko-solution`
                : ""
            }
            viewBox={`0 0 256 256`}
          />
        </div>
        <div className="mt-3 flex gap-2">
          <div className="text-[#646970]">{t("Unable to scan? You can use")} </div>
          <div className="flex gap-1 items-center">
            <span className="font-bold">{userData?.secret_key ? userData?.secret_key : ""}</span>
            <span className="cursor-pointer" onClick={onCopy}>
              {copyIcon}
            </span>
          </div>
        </div>
        <div className="text-[#646970]">{t("to manually configure your authenticator app.")}</div>
        {error !== "" && <AlertError error={error} />}
        <div className="mt-5">
          <Form
            name="normal_login"
            form={form}
            initialValues={{ remember: true }}
            onFinish={onFinish}
            onChange={onChange}
            layout="vertical"
          >
            <Form.Item
              label={<LabelInput label={t("Verification code")} required={true} />}
              required={false}
              name="code"
              rules={[{ required: true, message: t("Verification code is required") }]}
            >
              <Input size="large" placeholder={t("Enter the 6-digit code")} />
            </Form.Item>
          </Form>
        </div>
      </div>
    </Modal>
  )
}

const copyIcon = (
  <svg width="20" height="20" viewBox="0 0 20 20" fill="none" xmlns="http://www.w3.org/2000/svg">
    <g clipPath="url(#clip0_8069_33939)">
      <path
        d="M17.1426 0H5.17829C5.08008 0 4.99972 0.0803571 4.99972 0.178571V1.42857C4.99972 1.52679 5.08008 1.60714 5.17829 1.60714H16.2497V16.9643C16.2497 17.0625 16.3301 17.1429 16.4283 17.1429H17.6783C17.7765 17.1429 17.8569 17.0625 17.8569 16.9643V0.714286C17.8569 0.319196 17.5377 0 17.1426 0ZM14.2854 2.85714H2.85686C2.46177 2.85714 2.14258 3.17634 2.14258 3.57143V15.4174C2.14258 15.6071 2.21847 15.7879 2.3524 15.9219L6.2207 19.7902C6.26981 19.8393 6.32561 19.8795 6.38588 19.9129V19.9554H6.47963C6.55776 19.9844 6.64035 20 6.72517 20H14.2854C14.6805 20 14.9997 19.6808 14.9997 19.2857V3.57143C14.9997 3.17634 14.6805 2.85714 14.2854 2.85714ZM6.38365 17.683L4.46177 15.7589H6.38365V17.683ZM13.3926 18.3929H7.81222V15.2232C7.81222 14.7299 7.41267 14.3304 6.91936 14.3304H3.74972V4.46429H13.3926V18.3929Z"
        fill="#4DABD1"
      />
    </g>
    <defs>
      <clipPath id="clip0_8069_33939">
        <rect width="20" height="20" fill="white" />
      </clipPath>
    </defs>
  </svg>
)
