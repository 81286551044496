import { Button, Dropdown, Tooltip } from "antd"
// import { useEffect, useState } from "react"
import { getBaseDataLocal } from "lib/localstorage"
import { renderTime, getActiveAction } from "lib/function"
import dayjs from "dayjs"
import { Approval_Status } from "lib/Const"
import { useTranslation } from "react-i18next"

export default function BoxApproval({ item, onConfirm, isMyNotification, onMarkUnread, onRemove }) {
  const { t } = useTranslation()
  const { user } = getBaseDataLocal()
  const { data, title, _id, is_read, loading, created_at } = item

  const handleOk = async (e, v) => {
    e.stopPropagation()
    onConfirm(data?.id, v, _id, is_read)
  }

  function disabledButton() {
    if (data?.process?.approvers) {
      let findApprover = data?.process?.approvers.find((v) => v.id === user?.id)
      if (!findApprover) {
        return true
      }
    }
    return data?.status === 1 || data?.status === 2
  }

  function getBody() {
    if (data?.status === Approval_Status.pending) {
      return `${data?.created_by?.email} ${t("needs you to approve")} ${data?.name} ${t("request")}.`
    } else if (data?.status === Approval_Status.approved) {
      return `${data?.data?.user?.email} ${t("approved an approval")} ${data?.name} ${t("request")}.`
    }
    return `${data?.data?.user?.email} ${t("rejected an approval")} ${data?.name} ${t("request")}.`
  }

  return (
    <div>
      <div className="flex gap-4">
        <div>
          <div>{icApproval}</div>
        </div>
        <div className="w-full grid grid-cols-7 gap-4">
          <div className="col-span-5">
            <Tooltip placement="bottom" title={<div>{t(title)}</div>}>
              <div className="text-[16px] font-bold line-clamp-1">{t(title)}</div>
            </Tooltip>
            <Tooltip placement="bottom" title={<div>{getBody()}</div>}>
              <div className="text-[14px] text-[#646970] line-clamp-2">{getBody()}</div>
            </Tooltip>
          </div>
          <div className="col-span-2">
            <div className="flex justify-end gap-2 items-center">
              <div className="text-xs">{renderTime(dayjs(created_at))}</div>
              {!is_read && <div>{icActive}</div>}
              {isMyNotification && (
                <Dropdown
                  overlay={
                    <div className="shadow_antd w-[240px]">
                      <Button
                        className="w-full !text-start !flex !items-center"
                        type="text"
                        icon={icTick}
                        onClick={(e) => {
                          e.stopPropagation()
                          onMarkUnread(_id)
                        }}
                      >
                        {t("Mark as unread")}
                      </Button>
                      <Button
                        className="w-full !text-start !flex !items-center"
                        type="text"
                        icon={icClose}
                        onClick={(e) => {
                          e.stopPropagation()
                          onRemove(_id)
                        }}
                      >
                        {t("Remove this notification")}
                      </Button>
                    </div>
                  }
                >
                  <Button className="!p-[4px]" type="text" onClick={(e) => e.stopPropagation()}>
                    {icMore}
                  </Button>
                </Dropdown>
              )}
            </div>
          </div>
        </div>
      </div>

      {/* {data?.status === Approval_Status.in_process && !is_read && ( */}
      <div className="flex gap-4 justify-start mt-3">
        {getActiveAction("approve_approval") && (
          <Button
            type="primary"
            onClick={(e) => handleOk(e, true)}
            loading={loading}
            disabled={disabledButton()}
          >
            {t("Approve")}
          </Button>
        )}
        {getActiveAction("reject_approval") && (
          <Button
            type="primary"
            danger
            onClick={(e) => handleOk(e, false)}
            loading={loading}
            disabled={disabledButton()}
          >
            {t("Reject")}
          </Button>
        )}
      </div>
      {/* )} */}
    </div>
  )
}

const icActive = (
  <svg width="14" height="14" viewBox="0 0 14 14" fill="none" xmlns="http://www.w3.org/2000/svg">
    <rect x="1.25" y="1.25" width="11.5" height="11.5" rx="5.75" fill="#4DABD1" />
    <rect x="1.25" y="1.25" width="11.5" height="11.5" rx="5.75" stroke="white" strokeWidth="1.5" />
  </svg>
)
const icMore = (
  <svg width="16" height="16" viewBox="0 0 16 16" fill="none" xmlns="http://www.w3.org/2000/svg">
    <path
      d="M7 2.98438C7 3.24959 7.10536 3.50395 7.29289 3.69148C7.48043 3.87902 7.73478 3.98438 8 3.98438C8.26522 3.98438 8.51957 3.87902 8.70711 3.69148C8.89464 3.50395 9 3.24959 9 2.98438C9 2.71916 8.89464 2.4648 8.70711 2.27727C8.51957 2.08973 8.26522 1.98438 8 1.98438C7.73478 1.98438 7.48043 2.08973 7.29289 2.27727C7.10536 2.4648 7 2.71916 7 2.98438ZM7 7.98438C7 8.24959 7.10536 8.50395 7.29289 8.69148C7.48043 8.87902 7.73478 8.98438 8 8.98438C8.26522 8.98438 8.51957 8.87902 8.70711 8.69148C8.89464 8.50395 9 8.24959 9 7.98438C9 7.71916 8.89464 7.4648 8.70711 7.27727C8.51957 7.08973 8.26522 6.98438 8 6.98438C7.73478 6.98438 7.48043 7.08973 7.29289 7.27727C7.10536 7.4648 7 7.71916 7 7.98438ZM7 12.9844C7 13.2496 7.10536 13.5039 7.29289 13.6915C7.48043 13.879 7.73478 13.9844 8 13.9844C8.26522 13.9844 8.51957 13.879 8.70711 13.6915C8.89464 13.5039 9 13.2496 9 12.9844C9 12.7192 8.89464 12.4648 8.70711 12.2773C8.51957 12.0897 8.26522 11.9844 8 11.9844C7.73478 11.9844 7.48043 12.0897 7.29289 12.2773C7.10536 12.4648 7 12.7192 7 12.9844Z"
      fill="#646970"
    />
  </svg>
)
const icTick = (
  <svg width="20" height="20" viewBox="0 0 20 20" fill="none" xmlns="http://www.w3.org/2000/svg">
    <path
      d="M18.9296 2.8125H17.3694C17.1506 2.8125 16.943 2.91295 16.8091 3.08482L7.60597 14.7433L3.19302 9.15179C3.12627 9.06703 3.04118 8.9985 2.94414 8.95133C2.8471 8.90417 2.74065 8.8796 2.63276 8.87946H1.07249C0.922934 8.87946 0.840344 9.05134 0.931862 9.16741L7.0457 16.9129C7.33142 17.2746 7.88052 17.2746 8.16847 16.9129L19.0703 3.09821C19.1618 2.98438 19.0792 2.8125 18.9296 2.8125V2.8125Z"
      fill="#646970"
    />
  </svg>
)
const icClose = (
  <svg width="20" height="20" viewBox="0 0 20 20" fill="none" xmlns="http://www.w3.org/2000/svg">
    <path
      d="M11.1562 9.99895L17.0155 3.01458C17.1137 2.89851 17.0312 2.72217 16.8794 2.72217H15.0981C14.9932 2.72217 14.8928 2.76904 14.8236 2.8494L9.99097 8.61056L5.15838 2.8494C5.09142 2.76904 4.99097 2.72217 4.88383 2.72217H3.10258C2.95079 2.72217 2.8682 2.89851 2.96642 3.01458L8.82579 9.99895L2.96642 16.9833C2.94442 17.0092 2.9303 17.0408 2.92575 17.0745C2.9212 17.1082 2.9264 17.1424 2.94073 17.1732C2.95507 17.204 2.97794 17.23 3.00663 17.2482C3.03532 17.2664 3.06862 17.2759 3.10258 17.2757H4.88383C4.98874 17.2757 5.08919 17.2289 5.15838 17.1485L9.99097 11.3873L14.8236 17.1485C14.8905 17.2289 14.991 17.2757 15.0981 17.2757H16.8794C17.0312 17.2757 17.1137 17.0994 17.0155 16.9833L11.1562 9.99895Z"
      fill="#646970"
    />
  </svg>
)
const icApproval = (
  <svg width="40" height="40" viewBox="0 0 40 40" fill="none" xmlns="http://www.w3.org/2000/svg">
    <rect width="40" height="40" rx="20" fill="#E3F1F8" />
    <rect x="4" y="4" width="32" height="32" rx="16" fill="#8DC9E1" />
    <path
      d="M23.9289 15.5315V14.4601C23.9289 14.3619 23.8485 14.2815 23.7503 14.2815H15.1789C15.0806 14.2815 15.0003 14.3619 15.0003 14.4601V15.5315C15.0003 15.6297 15.0806 15.7101 15.1789 15.7101H23.7503C23.8485 15.7101 23.9289 15.6297 23.9289 15.5315ZM15.1789 17.4958C15.0806 17.4958 15.0003 17.5762 15.0003 17.6744V18.7458C15.0003 18.844 15.0806 18.9244 15.1789 18.9244H19.286C19.3842 18.9244 19.4646 18.844 19.4646 18.7458V17.6744C19.4646 17.5762 19.3842 17.4958 19.286 17.4958H15.1789ZM23.5717 20.0851C20.9088 20.0851 18.7503 22.2436 18.7503 24.9065C18.7503 27.5695 20.9088 29.728 23.5717 29.728C26.2347 29.728 28.3931 27.5695 28.3931 24.9065C28.3931 22.2436 26.2347 20.0851 23.5717 20.0851ZM25.9713 27.3061C25.3306 27.9467 24.478 28.2994 23.5717 28.2994C22.6655 28.2994 21.8128 27.9467 21.1722 27.3061C20.5315 26.6655 20.1789 25.8128 20.1789 24.9065C20.1789 24.0003 20.5315 23.1476 21.1722 22.507C21.8128 21.8664 22.6655 21.5137 23.5717 21.5137C24.478 21.5137 25.3306 21.8664 25.9713 22.507C26.6119 23.1476 26.9646 24.0003 26.9646 24.9065C26.9646 25.8128 26.6119 26.6655 25.9713 27.3061ZM25.5583 23.2101H24.5695C24.5114 23.2101 24.4579 23.2369 24.4244 23.2838L23.007 25.2436L22.4914 24.5315C22.4748 24.5085 22.453 24.4898 22.4278 24.477C22.4025 24.4642 22.3746 24.4576 22.3463 24.4579H21.3619C21.2168 24.4579 21.132 24.623 21.2168 24.7414L22.8641 27.0204C22.9355 27.1186 23.0806 27.1186 23.1521 27.0204L25.7012 23.4936C25.7882 23.3753 25.7034 23.2101 25.5583 23.2101ZM18.3931 27.5851H13.2146V11.8708H25.7146V19.5494C25.7146 19.6476 25.7949 19.728 25.8931 19.728H27.1431C27.2414 19.728 27.3217 19.6476 27.3217 19.5494V10.978C27.3217 10.5829 27.0025 10.2637 26.6074 10.2637H12.3217C11.9266 10.2637 11.6074 10.5829 11.6074 10.978V28.478C11.6074 28.873 11.9266 29.1922 12.3217 29.1922H18.3931C18.4914 29.1922 18.5717 29.1119 18.5717 29.0137V27.7637C18.5717 27.6655 18.4914 27.5851 18.3931 27.5851Z"
      fill="#E3F1F8"
    />
  </svg>
)
