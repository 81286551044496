import { ConfigProvider, notification } from "antd"
import { StyleProvider } from "@ant-design/cssinjs"
import useStore, { StoreProvider } from "components/ui/Context"
import Routes from "components/Router"
import { Fragment, useEffect } from "react"
import { myTheme } from "lib/theme"
import { applyTheme } from "lib/function"
import jp from "antd/locale/ja_JP"
import en from "antd/locale/en_US"
import "dayjs/locale/ja"
import "./i18n"
import "./App.scss"
import { CaretDownOutlined } from "@ant-design/icons"

notification.config({
  top: 0,
  duration: 3
})
function App() {
  useEffect(() => {
    window.addEventListener("error", (e) => {
      // prompt user to confirm refresh
      if (/Loading chunk [\d]+ failed/.test(e.message)) {
        window.location.reload()
      }
    })
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [])

  return (
    <StyleProvider hashPriority="high">
      <StoreProvider>
        <Main />
      </StoreProvider>
    </StyleProvider>
  )
}

export default App

function Main() {
  const { settingTheme, logOut, locale } = useStore()
  let colorPrimary = settingTheme?.color || myTheme.colorPrimary

  useEffect(() => {
    applyTheme(settingTheme)
  }, [settingTheme])

  useEffect(() => {
    function checkToken(e) {
      if (e.key === "isSignIn") {
        if (e.oldValue === "1" && e.newValue !== "1") {
          logOut()
          window.location.href = "/login"
        }
        if (e.oldValue !== "1" && e.newValue === "1") window.location.href = "/dashboard"
      }
    }
    window.addEventListener("storage", checkToken)
    return () => {
      window.removeEventListener("storage", checkToken)
    }
  }, [])

  return (
    <Fragment>
      <ConfigProvider
        locale={locale === "jp" ? jp : en}
        theme={{
          token: {
            colorPrimary: colorPrimary,
            borderRadius: myTheme.borderRadius,
            borderRadiusSM: myTheme.borderRadius,
            borderRadiusLG: myTheme.borderRadius,
            colorBorder: myTheme.colorBorder,
            fontFamily: settingTheme?.fontFamily || myTheme.fontFamily,
            controlHeightSM: 28,
            controlHeight: 40,
            controlHeightLG: 46,
            colorText: myTheme.colorText,
            fontSize: 16,
            colorTextPlaceholder: myTheme.colorTextPlaceholder,
            colorLink: "#0D7FAB"
          },
          components: {
            Breadcrumb: {
              lastItemColor: colorPrimary,
              itemColor: myTheme.colorText
            },
            Form: {
              labelFontSize: 16
            },
            Button: {
              contentFontSizeSM: 14,
              contentFontSize: 16
            },
            Table: {
              cellFontSizeSM: 14
            },
            Checkbox: {
              borderRadiusSM: 4
            }
          }
        }}
      >
        <Routes />
      </ConfigProvider>
    </Fragment>
  )
}
