import { Pagination } from "antd"
import { memo } from "react"
import { useTranslation } from "react-i18next"

const MyPagination = ({ page_num, onChange, page_size, count }) => {
  const { t } = useTranslation()
  const pageNum = Number(page_num)
  const pagaSize = Number(page_size)
  const toPage = pageNum * pagaSize > count ? count : pageNum * pagaSize
  const showTotal = count > 0 ? (pageNum - 1) * pagaSize + 1 : 0
  return (
    <div className="flex justify-end mt-4 items-center text-sm">
      <div>
        {showTotal} - {toPage} {t("of")} {count || 0} {t("items")}
      </div>
      {count > 0 && (
        <Pagination
          size="small"
          className="m-2"
          showSizeChanger
          current={pageNum}
          pageSize={pagaSize}
          pageSizeOptions={[10, 20, 40, 50, 100]}
          total={count}
          onChange={onChange}
          defaultCurrent={1}
          locale={{
            items_per_page: `/ ${t("page")}`
          }}
        />
      )}
    </div>
  )
}
export default memo(MyPagination)
