import Collapse from "components/Collapse"
import { useEffect, useState } from "react"
import { useNavigate, NavLink, useLocation } from "react-router-dom"
import { apiUser } from "api"
import { getCookieAuth } from "lib/cookie"
import { useStore } from "components/ui"
import confirmLogoutImage from "assets/images/confirmLogoutImage.png"
import { Image, Button, Modal, Tooltip } from "antd"
import cn from "classnames"
import { togleMenu } from "lib/icons"
import { useTranslation } from "react-i18next"
import BoxPricing from "./BoxPricing"

export default function Navbar({ items, togleSidebar, isNav, settingTheme }) {
  const { t } = useTranslation()
  const navigate = useNavigate()
  const location = useLocation()
  const { logOut, setMenuOption } = useStore()
  const [isModalOpen, setIsModalOpen] = useState(false)

  async function handleLogout() {
    try {
      await apiUser.logout({
        refresh_token: getCookieAuth()?.refresh_token
      })
    } catch (error) {
      console.log({ error })
    } finally {
      logOut()
      navigate("/login")
    }
  }

  useEffect(() => {
    if (isNav === "left-0") {
      if (document.body.style.overflow !== "hidden") {
        document.body.style.overflow = "hidden"
      }
    }
    return () => {
      document.body.style.overflow = null
      document.body.style.width = null
    }
  }, [isNav])
  // console.log({ settingTheme })

  const wapperClass = isNav ? "show__sider" : "hide__sider"
  const classTogleMenu = isNav ? "text-white right-2" : "text-dark-500 -right-10"
  return (
    <div
      className={`bg-primary-700 z-30 sticky h-screen border-r border-primary-300 top-0 shadow ease-in-out duration-300 ${wapperClass}`}
    >
      <div
        className={
          "relative border-b flex justify-between items-center border-primary-300 px-4 h-[63px] text-xl font-bold text-primary-50"
        }
      >
        <div className={cn("flex items-center", { "gap-4": isNav }, { "pl-1": !isNav })}>
          <img
            src={settingTheme?.logo}
            alt={t("Logo")}
            className={`w-[42px] h-[42px] rounded`}
            onClick={() => navigate("/dashboard")}
          />
          {isNav && (
            <Tooltip title={settingTheme.name}>
              <div className="text-ellipsis overflow-hidden whitespace-nowrap max-w-[180px]">
                {settingTheme.name}
              </div>
            </Tooltip>
          )}
        </div>

        <div
          onClick={togleSidebar}
          className={`absolute z-40  top-4 cursor-pointer p-2 ani rounded transition-all duration-300 ${classTogleMenu}`}
        >
          {togleMenu}
        </div>
      </div>
      <div className={isNav ? "overflow-y-auto __scroll" : ""}>
        <div className="flex flex-col h-full justify-between pt-4 text-sm">
          <ul className="space-y-2">
            {items.map((item) => {
              const { url, title, icon, children } = item
              const isCurrent = location.pathname.includes(url)
              if (children && children.length > 0) {
                return <MenuItem {...item} key={url} location={location} isNav={isNav} />
              } else {
                const activeClassName = isCurrent
                  ? "bg-primary-50 text-primary-600 font-bold hover:bg-primary-300"
                  : "text-primary-200 hover:bg-white/30"
                return (
                  <li key={url} className="px-4 mb-2 group relative dropdown">
                    <NavLink to={url}>
                      <div className={`${activeClassName} menu__item`}>
                        {icon}
                        <span className="text-ellipsis flex-auto overflow-hidden whitespace-nowrap">
                          {t(title)}
                        </span>
                      </div>
                    </NavLink>
                    {!isNav && (
                      <div className="group-hover:block dropdown-menu absolute top-[4px] left-[65px] hidden h-auto">
                        <div className="flex">
                          <div className="w-[25px] bg-transparent"></div>
                          <div class="top-0 bg-primary-700 shadow rounded-lg py-2 px-4 text-white text-ellipsis flex-auto whitespace-nowrap">
                            {t(title)}
                          </div>
                        </div>
                      </div>
                    )}
                  </li>
                )
              }
            })}
          </ul>
          <div className="min-h-[128px] p-4">
            {isNav && <BoxPricing setMenuOption={setMenuOption} />}
          </div>
        </div>
      </div>

      <ModalConfirmLogout
        isModalOpen={isModalOpen}
        setIsModalOpen={setIsModalOpen}
        onSubmit={handleLogout}
      />
    </div>
  )
}

function MenuItem({ title, icon, url, children, location, isNav }) {
  const { t } = useTranslation()
  const [isOpen, setOpen] = useState(children.find((i) => location.pathname.includes(i.url)))
  const isCurrent = location.pathname.includes(url)
  function onTogle() {
    setOpen((c) => !c)
  }
  const activeClassName = isCurrent
    ? "bg-primary-50 text-primary-600 font-bold hover:bg-primary-200"
    : "text-primary-200 hover:bg-white/30"
  const classIsDown = isOpen ? "rotate-180" : "rotate-0"

  return (
    <div className="sub__menu relative">
      <li className="px-4">
        <div
          onClick={() => isNav && onTogle()}
          className={`${activeClassName} rounded cursor-pointer
            h-11 px-4 flex items-center justify-between gap-4
            group relative dropdown
          `}
        >
          <div className="flex w-full items-center gap-4">
            {icon}
            <span className="text-ellipsis flex-auto overflow-hidden whitespace-nowrap">
              {t(title)}
            </span>
          </div>
          {isNav ? (
            <div className={`${classIsDown} flex items-center transition-all duration-300`}>
              {icDown}
            </div>
          ) : (
            <div className="group-hover:block dropdown-menu absolute top-[0px] left-[50px] hidden h-auto">
              <div className="flex">
                <div className="w-[25px] bg-transparent"></div>
                <ul class="top-0 w-60 bg-primary-700 shadow rounded-lg p-4">
                  {children.map((item) => {
                    return (
                      <li className="!mt-0" key={`${item.url}-${url}`}>
                        <NavLink
                          to={`${item.url}`}
                          className={({ isActive }) =>
                            isActive
                              ? "flex items-center text-primary-50 gap-4 py-1 px-4 border-l-[2px] border-primary-50 __active__sub__menu"
                              : "flex items-center text-primary-300 gap-4 py-1 px-4 border-l-[2px] border-primary-300"
                          }
                        >
                          <Tooltip
                            overlayInnerStyle={{ width: "max-content" }}
                            title={
                              item?.url === "/user/users" ? (
                                <div>{t("List of user accounts managed by admin")}</div>
                              ) : null
                            }
                            placement="right"
                          >
                            <span className="text-ellipsis flex items-center gap-2 flex-auto overflow-hidden whitespace-nowrap">
                              {t(item.title)}
                            </span>
                          </Tooltip>
                        </NavLink>
                      </li>
                    )
                  })}
                </ul>
              </div>
            </div>
          )}
        </div>
      </li>
      <div className="h-[1px]"></div>
      <Collapse isOpen={isOpen} isShow={isNav}>
        <ul className="p-2 pl-4 space-y-2 bg-white/10 rounded">
          {children.map((item) => {
            return (
              <li className="!mt-0" key={`${item.url}-${url}`}>
                <NavLink
                  to={`${item.url}`}
                  className={({ isActive }) =>
                    isActive
                      ? "flex items-center text-primary-50 gap-4 py-1 px-4 border-l-[2px] border-primary-50 __active__sub__menu"
                      : "flex items-center text-primary-300 gap-4 py-1 px-4 border-l-[2px] border-primary-300"
                  }
                >
                  <div className="flex justify-between w-full">
                    <Tooltip
                      overlayInnerStyle={{ width: "max-content" }}
                      title={
                        item?.url === "/user/users" ? (
                          <div>{t("List of user accounts managed by admin")}</div>
                        ) : null
                      }
                      placement="right"
                    >
                      <span className="text-ellipsis flex items-center gap-2 flex-auto overflow-hidden whitespace-nowrap">
                        {t(item.title)}
                      </span>
                    </Tooltip>
                    <div>{item.isLock && icLock}</div>
                  </div>
                </NavLink>
              </li>
            )
          })}
        </ul>
      </Collapse>
    </div>
  )
}

function ModalConfirmLogout({ onSubmit, isModalOpen, setIsModalOpen }) {
  const handleCancel = () => {
    setIsModalOpen(false)
  }

  return (
    <Modal className="!max-w-[400px]" open={isModalOpen} onCancel={handleCancel} footer={<></>}>
      <div className="flex justify-center mt-10">
        <Image width={151} height={117} preview={false} src={confirmLogoutImage} />
      </div>
      <h3 className="text-[20px] font-bold text-[#1C3E4B] text-center mt-8 mb-3">Confirm logout</h3>
      <p className="text-[#646970] text-center mb-5">Are you sure you want to log out?</p>

      <div className="flex justify-between gap-4">
        <Button size="large" className="!rounded-lg w-full" onClick={handleCancel}>
          Cancel
        </Button>
        <Button
          type="primary"
          danger
          size="large"
          className="!rounded-lg w-full"
          onClick={onSubmit}
        >
          Log out
        </Button>
      </div>
    </Modal>
  )
}

const icDown = (
  <svg width="16" height="16" viewBox="0 0 16 16" fill="none" xmlns="http://www.w3.org/2000/svg">
    <path
      d="M13.1315 4.6875H2.86897C2.56116 4.6875 2.38929 5.0125 2.57991 5.23438L7.71116 11.1844C7.85804 11.3547 8.14085 11.3547 8.28929 11.1844L13.4205 5.23438C13.6112 5.0125 13.4393 4.6875 13.1315 4.6875Z"
      fill="currentColor"
    />
  </svg>
)

const icLock = (
  <svg width="24" height="24" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
    <path
      d="M19.5 10.875H17.9062V5.625C17.9062 3.96797 16.5633 2.625 14.9062 2.625H9.09375C7.43672 2.625 6.09375 3.96797 6.09375 5.625V10.875H4.5C4.08516 10.875 3.75 11.2102 3.75 11.625V20.625C3.75 21.0398 4.08516 21.375 4.5 21.375H19.5C19.9148 21.375 20.25 21.0398 20.25 20.625V11.625C20.25 11.2102 19.9148 10.875 19.5 10.875ZM12.6562 16.4297V17.6719C12.6562 17.775 12.5719 17.8594 12.4688 17.8594H11.5312C11.4281 17.8594 11.3438 17.775 11.3438 17.6719V16.4297C11.1503 16.2908 11.0059 16.0941 10.9313 15.8679C10.8568 15.6417 10.8559 15.3977 10.9289 15.1709C11.0018 14.9442 11.1448 14.7465 11.3373 14.6062C11.5298 14.466 11.7618 14.3904 12 14.3904C12.2382 14.3904 12.4702 14.466 12.6627 14.6062C12.8552 14.7465 12.9982 14.9442 13.0711 15.1709C13.1441 15.3977 13.1432 15.6417 13.0687 15.8679C12.9941 16.0941 12.8497 16.2908 12.6562 16.4297ZM16.2188 10.875H7.78125V5.625C7.78125 4.90078 8.36953 4.3125 9.09375 4.3125H14.9062C15.6305 4.3125 16.2188 4.90078 16.2188 5.625V10.875Z"
      fill="currentColor"
    />
  </svg>
)
