import React, { useState } from "react"
import { Dropdown, Avatar, Tooltip } from "antd"
import { useNavigate, Link } from "react-router-dom"
import { useStore } from "components/ui"
import { apiUser } from "api"
import { getCookieAuth } from "lib/cookie"
import Notification from "components/NotificationDropdown/Notification"
import ActivityLog from "components/ActivityLogDropdown/ActivityLog"
import { getActiveAction } from "lib/function"
import { RESOURCE_KEY } from "lib/Const"
import ButtonI18n from "./ButtonI18n"
import { useTranslation } from "react-i18next"

const Header = ({ user }) => {
  const { t } = useTranslation()
  const navigate = useNavigate()
  const { logOut } = useStore()
  const [isHover, setIsHover] = useState(false)

  async function handleLogout() {
    try {
      await apiUser.logout({
        refresh_token: getCookieAuth()?.refresh_token
      })
    } catch (error) {
      console.log({ error })
    } finally {
      logOut()
      navigate("/login")
    }
  }
  return (
    <div className="flex gap-4 items-center">
      {getActiveAction(RESOURCE_KEY.ACTIVITY_LOG) && <ActivityLog />}
      <Notification />
      <ButtonI18n />
      {/* <DropdownLanguage /> */}
      <Dropdown
        menu={{
          items: [
            {
              label: (
                <Link to="/profile">
                  <div className="text-gray-900 font-medium rounded flex px-4 gap-2 items-center hover:bg-gray-100">
                    <div>{icSetting}</div>
                    <div>{t("User profile")}</div>
                  </div>
                </Link>
              ),
              key: "3"
            },
            // {
            //   type: "divider"
            // },
            {
              label: (
                <div
                  onClick={handleLogout}
                  className="text-[#F87171] font-medium rounded flex px-4 gap-2 items-center hover:bg-gray-100"
                >
                  <div>{icLog}</div>
                  {t("Logout")}
                </div>
              ),
              key: ""
            }
          ]
        }}
        placement="bottomLeft"
        onOpenChange={(v) => setIsHover(v)}
      >
        <div className="cursor-pointer flex jutify-center items-center gap-2">
          <div className="flex items-center justify-center hover-sb ">
            <div className="relative">
              <Avatar
                size={46}
                onClick={(e) => e.preventDefault()}
                src={
                  user?.additional_information?.avatar ||
                  "https://cdn1.iconfinder.com/data/icons/user-pictures/100/unknown-512.png"
                }
              />
              <div className="absolute bottom-0 right-0">{icActive}</div>
            </div>
            <div className="text-sm ml-2">
              <Tooltip
                placement="bottom"
                title={
                  <div>
                    {user?.first_name} {user?.last_name}
                  </div>
                }
              >
                <div className="text-[14px] font-[600] text-dark-500 max-w-[200px] line-clamp-1">
                  {user?.first_name} {user?.last_name}
                </div>
              </Tooltip>

              <Tooltip placement="bottom" title={<div>{user?.email}</div>}>
                <div className="text-xs font-normal text-dark-400 max-w-[200px] line-clamp-1">
                  {user?.email}
                </div>
              </Tooltip>
            </div>
          </div>
          <svg
            width="16"
            height="16"
            viewBox="0 0 16 16"
            fill="none"
            xmlns="http://www.w3.org/2000/svg"
          >
            <path
              d="M13.1315 4.6875H2.86897C2.56116 4.6875 2.38929 5.0125 2.57991 5.23438L7.71116 11.1844C7.85804 11.3547 8.14085 11.3547 8.28929 11.1844L13.4205 5.23438C13.6112 5.0125 13.4393 4.6875 13.1315 4.6875Z"
              fill="currentColor"
            />
          </svg>
        </div>
      </Dropdown>
    </div>
  )
}

export default Header

const icSetting = (
  <svg width="20" height="20" viewBox="0 0 20 20" fill="none" xmlns="http://www.w3.org/2000/svg">
    <path
      d="M17.7337 15.6133C17.3127 14.6159 16.7016 13.7099 15.9346 12.9459C15.17 12.1796 14.2642 11.5687 13.2672 11.1468C13.2583 11.1423 13.2494 11.1401 13.2404 11.1356C14.6311 10.1311 15.5351 8.49498 15.5351 6.649C15.5351 3.59096 13.0574 1.11328 9.99936 1.11328C6.94132 1.11328 4.46364 3.59096 4.46364 6.649C4.46364 8.49498 5.36766 10.1311 6.75829 11.1378C6.74936 11.1423 6.74043 11.1445 6.7315 11.149C5.7315 11.5709 4.83418 12.1758 4.06409 12.9481C3.29782 13.7128 2.68687 14.6186 2.26498 15.6155C1.85052 16.5915 1.627 17.6379 1.6065 18.6981C1.60591 18.7219 1.61008 18.7456 1.61879 18.7678C1.6275 18.79 1.64056 18.8102 1.6572 18.8273C1.67384 18.8443 1.69373 18.8579 1.71569 18.8671C1.73765 18.8764 1.76124 18.8811 1.78507 18.8811H3.12436C3.22257 18.8811 3.3007 18.803 3.30293 18.707C3.34757 16.9838 4.03954 15.37 5.26275 14.1468C6.52838 12.8811 8.20918 12.1847 9.99936 12.1847C11.7895 12.1847 13.4703 12.8811 14.736 14.1468C15.9592 15.37 16.6511 16.9838 16.6958 18.707C16.698 18.8052 16.7761 18.8811 16.8744 18.8811H18.2136C18.2375 18.8811 18.2611 18.8764 18.283 18.8671C18.305 18.8579 18.3249 18.8443 18.3415 18.8273C18.3582 18.8102 18.3712 18.79 18.3799 18.7678C18.3886 18.7456 18.3928 18.7219 18.3922 18.6981C18.3699 17.6311 18.1489 16.5932 17.7337 15.6133V15.6133ZM9.99936 10.4883C8.97481 10.4883 8.01052 10.0887 7.28507 9.36328C6.55963 8.63784 6.16007 7.67355 6.16007 6.649C6.16007 5.62444 6.55963 4.66016 7.28507 3.93471C8.01052 3.20926 8.97481 2.80971 9.99936 2.80971C11.0239 2.80971 11.9882 3.20926 12.7136 3.93471C13.4391 4.66016 13.8386 5.62444 13.8386 6.649C13.8386 7.67355 13.4391 8.63784 12.7136 9.36328C11.9882 10.0887 11.0239 10.4883 9.99936 10.4883Z"
      fill="#646970"
    />
  </svg>
)

const icLog = (
  <svg width="20" height="20" viewBox="0 0 20 20" fill="none" xmlns="http://www.w3.org/2000/svg">
    <g clip-path="url(#clip0_9708_19415)">
      <path
        d="M17.9465 14.9113H16.3773C16.2702 14.9113 16.1697 14.9582 16.1028 15.0408C15.9465 15.2305 15.7791 15.4135 15.6028 15.5876C14.8815 16.3096 14.0272 16.8849 13.0871 17.2818C12.1132 17.6932 11.0663 17.9043 10.009 17.9024C8.93981 17.9024 7.9041 17.6926 6.93088 17.2818C5.99077 16.8849 5.13648 16.3096 4.41526 15.5876C3.69275 14.8681 3.11663 14.0153 2.71883 13.0765C2.30588 12.1033 2.09829 11.0698 2.09829 10.0006C2.09829 8.9314 2.30811 7.89791 2.71883 6.9247C3.11615 5.98497 3.68758 5.13899 4.41526 4.41354C5.14294 3.68809 5.98892 3.11666 6.93088 2.71934C7.9041 2.30863 8.93981 2.09881 10.009 2.09881C11.0782 2.09881 12.1139 2.3064 13.0871 2.71934C14.0291 3.11666 14.8751 3.68809 15.6028 4.41354C15.7791 4.58988 15.9443 4.77291 16.1028 4.96041C16.1697 5.043 16.2724 5.08988 16.3773 5.08988H17.9465C18.0871 5.08988 18.1742 4.93363 18.0961 4.81532C16.384 2.15461 13.3885 0.393449 9.98445 0.402378C4.63624 0.41577 0.348292 4.75729 0.401864 10.0988C0.455435 15.3555 4.73669 19.5988 10.009 19.5988C13.4041 19.5988 16.3862 17.8399 18.0961 15.1859C18.172 15.0676 18.0871 14.9113 17.9465 14.9113ZM19.9309 9.85997L16.7635 7.35997C16.6452 7.26622 16.4733 7.35104 16.4733 7.50059V9.19702H9.46437C9.36615 9.19702 9.28579 9.27738 9.28579 9.37559V10.6256C9.28579 10.7238 9.36615 10.8042 9.46437 10.8042H16.4733V12.5006C16.4733 12.6501 16.6474 12.735 16.7635 12.6412L19.9309 10.1412C19.9522 10.1245 19.9695 10.1032 19.9814 10.0788C19.9932 10.0544 19.9994 10.0277 19.9994 10.0006C19.9994 9.97349 19.9932 9.94674 19.9814 9.92238C19.9695 9.89801 19.9522 9.87667 19.9309 9.85997V9.85997Z"
        fill="#F87171"
      />
    </g>
    <defs>
      <clipPath id="clip0_9708_19415">
        <rect width="20" height="20" fill="white" />
      </clipPath>
    </defs>
  </svg>
)

const icActive = (
  <svg width="14" height="14" viewBox="0 0 14 14" fill="none" xmlns="http://www.w3.org/2000/svg">
    <rect x="1.25" y="1.25" width="11.5" height="11.5" rx="5.75" fill="#12B981" />
    <rect x="1.25" y="1.25" width="11.5" height="11.5" rx="5.75" stroke="white" strokeWidth="1.5" />
  </svg>
)
