import { Form, Input, Button } from "antd"
import { useState, useRef, useEffect } from "react"
import { EyeTwoTone, EyeInvisibleOutlined } from "@ant-design/icons"
import LabelInput from "components/ui/LabelInput"
import axios from "axios"
import { apiUser } from "api"
import { hasWhiteSpace } from "lib/validate"
import ModalPopupConfirm from "components/ModalPopupConfirm"
import "./Login.scss"
import { getListSetting } from "lib/passwordPolicy"
import AlertError from "components/AlertError"
import { useTranslation } from "react-i18next"

const baseDomain = process.env.REACT_APP_ID_URL

const ChangePassword = ({ auth, user, onNext, onBack }) => {
  const { t } = useTranslation()
  const [form] = Form.useForm()
  const formRef = useRef(null)
  const [loading, setLoading] = useState(false)
  const [showModal, setShowModal] = useState(false)
  const [settings, setSettings] = useState([])
  const [isPass, setIsPass] = useState(true)
  const [isSubmit, setIsSubmit] = useState(false)
  const [error, setError] = useState("")

  async function fetchSetting() {
    setLoading(true)
    let _rows = []
    try {
      const { data } = await apiUser.getPasswordSetting({ user_id: user?.id })
      _rows = getListSetting(Object.keys(data), Object.values(data))
      setSettings(_rows)
    } catch (e) {
      console.log(e)
    } finally {
      setLoading(false)
    }
  }

  // Callback function when the form is submitted
  const onFinish = async (values) => {
    setError("")
    const { access_token } = auth
    if (loading) {
      return
    }

    setLoading(true)
    axios({
      url: `${baseDomain}/api/v1/account/reset-password/`,
      data: values,
      method: "put",
      headers: {
        Authorization: `Bearer ${access_token}`,
        "Content-Type": "application/json"
      }
    })
      .then((response) => {
        console.log(response)
        setLoading(false)
        setShowModal(true)
      })
      .catch((error) => {
        setLoading(false)
        const { data } = error.response
        setError(t(data?.error_message[0]))
      })
  }

  function onBackLogin() {
    setShowModal(false)
    onNext()
  }

  const onChange = (key, value) => {
    setIsSubmit(true)
    setError("")
    form.validateFields()
    if (key === "new_password") {
      var _arr = [...settings]
      for (let index = 0; index < _arr.length; index++) {
        if (new RegExp(_arr[index].regex).test(value)) {
          _arr[index].is_active = true
        } else {
          _arr[index].is_active = false
        }
      }
      const filter = _arr.filter((v) => v.is_active)
      setIsPass(false)
      if (filter.length === _arr.length) {
        setIsPass(true)
      }
      setSettings(_arr)
    }
  }

  useEffect(() => {
    fetchSetting()
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [])

  return (
    <div className="">
      <h3 className="text-[24px] text-blue-dark font-bold text-center mt-8 mb-6">
        {t("Change password")}
      </h3>

      {error !== "" && <AlertError error={error} />}

      <Form
        name="normal_login"
        initialValues={{ remember: true }}
        onFinish={onFinish}
        layout="vertical"
        form={form}
        ref={formRef}
      >
        <Form.Item
          label={<LabelInput label={t("New password")} required={true} />}
          name="new_password"
          required={false}
          rules={[
            { required: true, message: t("New password is required") },
            {
              validator: async (rule, value) => {
                if (value && hasWhiteSpace(value)) {
                  return Promise.reject(t("Your password should not have whitespace"))
                }
                return Promise.resolve()
              }
            }
          ]}
          className="mb-8"
        >
          <Input.Password
            size="large"
            placeholder={t("Enter new password")}
            iconRender={(visible) => (visible ? <EyeTwoTone /> : <EyeInvisibleOutlined />)}
            onChange={(e) => onChange("new_password", e.target.value)}
          />
        </Form.Item>
        <Form.Item
          label={<LabelInput label={t("Re-enter new password")} required={true} />}
          name="new_password_confirm"
          required={false}
          rules={[
            { required: true, message: t("Re-enter new password is required") },
            {
              validator: async (rule, value) => {
                const _password = form.getFieldValue("new_password")
                if (value && value !== _password) {
                  return Promise.reject(t("The confirming passwords you entered do not match"))
                }
                return Promise.resolve()
              }
            }
          ]}
          className="mb-8"
        >
          <Input.Password
            size="large"
            placeholder={t("Re-enter password")}
            iconRender={(visible) => (visible ? <EyeTwoTone /> : <EyeInvisibleOutlined />)}
            onChange={(e) => onChange("new_password_confirm", e.target.value)}
          />
        </Form.Item>

        <div className="mt-2">
          {!isPass &&
            settings.length > 0 &&
            settings.map((v) => (
              <div className="my-2 flex gap-2 items-center" key={v?.key}>
                {v.is_active ? <div>{successIcon}</div> : <div>{errorIcon}</div>}
                <p className="text-xs text-[#646970] font-[400]">{v?.label}</p>
              </div>
            ))}
        </div>

        {/* Submit button */}
        <Form.Item>
          <Button
            type="primary"
            size="large"
            htmlType="submit"
            className="w-full mt-5 !rounded-lg"
            loading={loading}
            disabled={!isSubmit}
          >
            {t("Change password")}
          </Button>
        </Form.Item>
      </Form>

      <div className="text-center mt-[16px]">
        <span>
          {t("Back to")} {/* eslint-disable-next-line jsx-a11y/anchor-is-valid */}
          <a className="cursor-pointer text-primary-700" onClick={onBack}>
            {t("Sign in")}
          </a>
        </span>
      </div>

      <ModalPopupConfirm
        visible={showModal}
        title={t("Password saved")}
        desctiption={t("Your password has been saved successfully.")}
        titleButton={t("Back to Log in")}
        onSubmit={onBackLogin}
      />
    </div>
  )
}

export default ChangePassword

const successIcon = (
  <svg width="16" height="16" viewBox="0 0 16 16" fill="none" xmlns="http://www.w3.org/2000/svg">
    <path
      d="M8 1C4.13438 1 1 4.13438 1 8C1 11.8656 4.13438 15 8 15C11.8656 15 15 11.8656 15 8C15 4.13438 11.8656 1 8 1ZM11.0234 5.71406L7.73281 10.2766C7.68682 10.3408 7.62619 10.3931 7.55595 10.4291C7.48571 10.4652 7.40787 10.4841 7.32891 10.4841C7.24994 10.4841 7.17211 10.4652 7.10186 10.4291C7.03162 10.3931 6.97099 10.3408 6.925 10.2766L4.97656 7.57656C4.91719 7.49375 4.97656 7.37813 5.07812 7.37813H5.81094C5.97031 7.37813 6.12187 7.45469 6.21562 7.58594L7.32812 9.12969L9.78438 5.72344C9.87813 5.59375 10.0281 5.51562 10.1891 5.51562H10.9219C11.0234 5.51562 11.0828 5.63125 11.0234 5.71406Z"
      fill="#12B981"
    />
  </svg>
)
const errorIcon = (
  <svg width="16" height="16" viewBox="0 0 16 16" fill="none" xmlns="http://www.w3.org/2000/svg">
    <path
      d="M8 1C4.13438 1 1 4.13438 1 8C1 11.8656 4.13438 15 8 15C11.8656 15 15 11.8656 15 8C15 4.13438 11.8656 1 8 1ZM10.5844 10.6594L9.55313 10.6547L8 8.80313L6.44844 10.6531L5.41563 10.6578C5.34688 10.6578 5.29063 10.6031 5.29063 10.5328C5.29063 10.5031 5.30156 10.475 5.32031 10.4516L7.35313 8.02969L5.32031 5.60938C5.30143 5.58647 5.29096 5.5578 5.29063 5.52812C5.29063 5.45937 5.34688 5.40312 5.41563 5.40312L6.44844 5.40781L8 7.25938L9.55156 5.40938L10.5828 5.40469C10.6516 5.40469 10.7078 5.45937 10.7078 5.52969C10.7078 5.55937 10.6969 5.5875 10.6781 5.61094L8.64844 8.03125L10.6797 10.4531C10.6984 10.4766 10.7094 10.5047 10.7094 10.5344C10.7094 10.6031 10.6531 10.6594 10.5844 10.6594Z"
      fill="#F87171"
    />
  </svg>
)
