import { Spin, Tabs, Button, notification } from "antd"
import { apiNotification, apiApproval } from "api"
import { TYPE_BLOCK_NOTI } from "lib/Const"
import useComponentVisible from "lib/click-outside"
import { useEffect, useState, useRef } from "react"
import { Link } from "react-router-dom"
// import { Approval_Status } from "lib/Const"
import BoxApproval from "./BoxApproval"
import BoxOther from "./BoxOther"
import ModalNotificationDetail from "components/ModalNotificationDetail"
import ModalUpdateApproval from "pages/ApprovalList/ModalUpdateApproval"
import "./index.scss"
import { ButtonSecondary } from "components/ui"
import { useTranslation } from "react-i18next"

export default function Notification() {
  const { t } = useTranslation()
  const { ref, isComponentVisible, setIsComponentVisible } = useComponentVisible(false)
  const listInnerRef = useRef()
  // const [open, setOpen] = useState(false)
  const [hasNewNoti, setHasNewNoti] = useState(false)
  const [activeKey, setActiveKey] = useState("")
  const [loading, setLoading] = useState(false)
  const [rows, setRows] = useState([])
  const [categories, setCategories] = useState([
    {
      id: 1,
      is_read: null,
      name: "All",
      count: 0
    },
    { id: 2, is_read: true, name: "Read", count: 0 },
    { id: 3, is_read: false, name: "Unread", count: 0 }
  ])
  const [showDetail, setShowDetail] = useState(false)
  const [showDetailApproval, setShowDetailApproval] = useState(false)
  const __pagination = useRef({
    page_num: 1,
    page_size: 10,
    has_next: false,
    category_id: "",
    is_read: null,
    user_type: 1
  })
  const __item = useRef(false)

  const fetchCategory = async () => {
    setLoading(true)
    try {
      const { data } = await apiNotification.getCategories()
      const find = data?.find((v) => (v.name = "All"))
      __pagination.current.category_id = find?.id
      fetchNotiByCategory()
    } catch (e) {
      console.log(e)
    } finally {
      setLoading(false)
    }
  }

  const fetchNotiByCategory = async (noti_id) => {
    setLoading(true)
    try {
      const resNoti = await apiNotification.getNotificationByCategory(__pagination.current)
      __pagination.current.has_next = resNoti.has_next
      setCategories(
        categories.map((v) => {
          let item = { ...v }
          if (item.is_read === null) {
            item.count = resNoti?.all_count
          } else if (!item.is_read) {
            item.count = resNoti?.unread_count
          } else {
            item.count = resNoti?.read_count
          }
          return item
        })
      )
      if (noti_id) {
        const find = resNoti.data.find((v) => v._id === noti_id)
        if (find) {
          var _arr = [...rows]
          const findNotiIndex = _arr.findIndex((v) => v._id === noti_id)
          if (findNotiIndex > -1) {
            _arr[findNotiIndex] = find
          }
          setRows(_arr)
        } else {
          setRows(resNoti.data)
        }
      } else {
        setRows((prev) => [...prev, ...resNoti.data])
      }
    } catch (e) {
      console.log(e)
    } finally {
      setLoading(false)
    }
  }

  const fetchCountNoti = async () => {
    try {
      const { data } = await apiNotification.getCountNoti({ user_type: 1 })
      if (data > 0) {
        setHasNewNoti(true)
      } else {
        setHasNewNoti(false)
      }
    } catch (e) {
      console.log(e)
    } finally {
    }
  }

  function setLoadingItem(id, isLoading) {
    setRows((v) => {
      let _new = [...v]
      v.forEach((item, index) => {
        if (item._id === id) {
          _new[index].loading = isLoading
        }
      })
      return _new
    })
  }

  const fetchDetail = async (id, value, noti_id, is_read) => {
    setLoadingItem(noti_id, true)
    onUpdateStatus(id, value, noti_id)
    // try {
    //   const { data } = await apiApproval.get(id)
    //   __item.current = data
    //   onUpdateStatus(id, value, noti_id)
    // } catch (e) {
    //   setLoadingItem(noti_id, false)
    // } finally {
    // }
  }

  const onMarkReadNoti = async (id) => {
    try {
      await apiNotification.markReadByNoti(id, true)
    } catch (e) {
      console.log(e)
    } finally {
      fetchNotiByCategory(id)
      fetchCountNoti()
    }
  }

  const onMarkReadAllNoti = async () => {
    try {
      await apiNotification.markReadAllNoti({
        is_all: true,
        is_read: true,
        category_id: __pagination.current.category_id
      })
    } catch (e) {
      console.log(e)
    } finally {
      setRows([])
      __pagination.current.page_num = 1
      fetchNotiByCategory()
      fetchCountNoti()
    }
  }

  const onUpdateStatus = async (id, v, noti_id) => {
    try {
      await apiApproval.updateStatus(id, {
        approved: v,
        notes: null,
        noti_id: noti_id
      })
      if (v) {
        notification.success({ description: t("Request has been approved successfully") })
      } else {
        notification.success({ description: t("Request has been rejected successfully") })
      }
      // onMarkReadNoti(noti_id)
    } catch (error) {
      console.log(error)
    } finally {
      setLoadingItem(noti_id, false)
      onMarkReadNoti(noti_id)
    }
  }

  async function onChange(v) {
    setActiveKey(v)
    setRows([])
    __pagination.current.page_num = 1
    if (v === 1) {
      __pagination.current.is_read = null
    } else if (v === 2) {
      __pagination.current.is_read = true
    } else {
      __pagination.current.is_read = false
    }
    fetchNotiByCategory()
  }

  const onViewDetail = (item) => {
    const { _id, is_read, type } = item
    __item.current = item
    if (!is_read) {
      onMarkReadNoti(_id, true)
    }
    if (type === 1) {
      setShowDetailApproval(true)
    } else {
      setShowDetail(true)
    }

    // setIsComponentVisible(false)
    // navigate(`/notification/${_id}`, { state: { data: item } })
  }

  const onScroll = () => {
    if (listInnerRef.current) {
      const { scrollTop, scrollHeight, clientHeight } = listInnerRef.current
      const isNearBottom = scrollTop + clientHeight + 3 >= scrollHeight

      if (isNearBottom && __pagination.current.has_next) {
        // DO SOMETHING HERE
        __pagination.current.page_num = __pagination.current.page_num + 1
        fetchNotiByCategory()
      }
    }
  }

  useEffect(() => {
    const listInnerElement = listInnerRef.current

    if (listInnerElement) {
      listInnerElement.addEventListener("scroll", onScroll)

      // Clean-up
      return () => {
        listInnerElement.removeEventListener("scroll", onScroll)
      }
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [isComponentVisible])

  useEffect(() => {
    // fetch()
    setActiveKey(1)
    fetchCountNoti()
    fetchCategory()
    // fetchNotiByCategory()
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [])

  return (
    <div className="w-10 flex justify-center items-center">
      <div
        className="cursor-pointer hover:bg-[#E3F1F8] hover:rounded-[8px]"
        style={{
          backgroundColor: isComponentVisible && "#E3F1F8",
          borderRadius: isComponentVisible && 8
        }}
        onClick={() => setIsComponentVisible(true)}
      >
        {hasNewNoti ? icActiveNoti : icNoti}
      </div>
      <div ref={ref}>
        {isComponentVisible && (
          <div className="fixed right-10 w-[460px] top-16 z-20 shadow bg-white px-4 py-4 rounded">
            <Spin spinning={loading}>
              <div>
                <div className="pb-2 flex justify-between items-center">
                  <div className="text-[18px] font-bold">{t("Notification")}</div>
                  <div>
                    <Button
                      className="!text-[16px] !text-primary-600 !font-medium"
                      type="text"
                      onClick={onMarkReadAllNoti}
                    >
                      {t("Mark as all read")}
                    </Button>
                  </div>
                </div>
                <div className="__tab__container">
                  <Tabs
                    className="tabs__custom__dropdown"
                    activeKey={activeKey}
                    // centered
                    items={categories.map((i) => {
                      return {
                        key: i.id,
                        label: `${t(i.name)} (${i.count})`
                      }
                    })}
                    onChange={onChange}
                  />
                </div>
              </div>

              <div
                className="mt-2 overflow-y-auto"
                style={{ height: window.screen.height > 900 ? "450px" : "250px" }}
                ref={listInnerRef}
              >
                {rows?.length > 0 ? (
                  rows?.map((i, key) => {
                    return (
                      <div
                        key={key}
                        className="p-2 mb-3 cursor-pointer"
                        onClick={() => onViewDetail(i)}
                      >
                        {i.type === TYPE_BLOCK_NOTI.APPROVAL && (
                          <BoxApproval item={i} onConfirm={fetchDetail} />
                        )}
                        {i.type === TYPE_BLOCK_NOTI.NEWS && <BoxOther item={i} />}
                        {i.type === TYPE_BLOCK_NOTI.MARKETING && <BoxOther item={i} />}
                        {i.type === TYPE_BLOCK_NOTI.PROMOTION && <BoxOther item={i} />}
                        {i.type === TYPE_BLOCK_NOTI.EVENT && <BoxOther item={i} />}
                        {i.type === TYPE_BLOCK_NOTI.TASK && <BoxOther item={i} />}
                      </div>
                    )
                  })
                ) : (
                  <div className="flex justify-center items-center">{t("No data")}</div>
                )}
              </div>
              <div className="mx-3">
                <Link to="/my-notification" onClick={() => setIsComponentVisible(false)}>
                  <ButtonSecondary
                    className="w-full"
                    size="large"
                    onClick={(e) => e.stopPropagation()}
                  >
                    {t("See more notification")}
                  </ButtonSecondary>
                </Link>
              </div>
            </Spin>
          </div>
        )}
      </div>

      <ModalNotificationDetail
        open={showDetail}
        item={__item.current}
        onClose={(e) => {
          e.stopPropagation()
          setShowDetail(false)
          setIsComponentVisible(true)
        }}
      />
      <ModalUpdateApproval
        open={showDetailApproval}
        item={__item.current?.data}
        noti_id={__item.current?._id}
        onClose={(e) => {
          e.stopPropagation()
          setShowDetailApproval(false)
          setIsComponentVisible(true)
        }}
        onConfirm={(e) => {
          e.stopPropagation()
          setShowDetailApproval(false)
          if (!__item.current?.is_read) {
            onMarkReadNoti(__item.current?._id, true)
          }
          setIsComponentVisible(true)
        }}
      />
    </div>
  )
}

const icNoti = (
  <svg width="40" height="40" viewBox="0 0 40 40" fill="none" xmlns="http://www.w3.org/2000/svg">
    <path
      d="M28.1429 26.8538H27.5V17.7467C27.5 13.9672 24.7063 10.844 21.0714 10.3243V9.28237C21.0714 8.6904 20.592 8.21094 20 8.21094C19.408 8.21094 18.9286 8.6904 18.9286 9.28237V10.3243C15.2938 10.844 12.5 13.9672 12.5 17.7467V26.8538H11.8571C11.383 26.8538 11 27.2368 11 27.7109V28.5681C11 28.6859 11.0964 28.7824 11.2143 28.7824H17C17 30.4377 18.3446 31.7824 20 31.7824C21.6554 31.7824 23 30.4377 23 28.7824H28.7857C28.9036 28.7824 29 28.6859 29 28.5681V27.7109C29 27.2368 28.617 26.8538 28.1429 26.8538ZM20 30.0681C19.2902 30.0681 18.7143 29.4922 18.7143 28.7824H21.2857C21.2857 29.4922 20.7098 30.0681 20 30.0681ZM14.4286 26.8538V17.7467C14.4286 16.2574 15.0071 14.8592 16.0598 13.8065C17.1125 12.7538 18.5107 12.1752 20 12.1752C21.4893 12.1752 22.8875 12.7538 23.9402 13.8065C24.9929 14.8592 25.5714 16.2574 25.5714 17.7467V26.8538H14.4286Z"
      fill="#4DABD1"
    />
  </svg>
)
const icActiveNoti = (
  <svg width="40" height="40" viewBox="0 0 40 40" fill="none" xmlns="http://www.w3.org/2000/svg">
    <path
      d="M28.1429 26.8538H27.5V17.7467C27.5 13.9672 24.7063 10.844 21.0714 10.3243V9.28237C21.0714 8.6904 20.592 8.21094 20 8.21094C19.408 8.21094 18.9286 8.6904 18.9286 9.28237V10.3243C15.2938 10.844 12.5 13.9672 12.5 17.7467V26.8538H11.8571C11.383 26.8538 11 27.2368 11 27.7109V28.5681C11 28.6859 11.0964 28.7824 11.2143 28.7824H17C17 30.4377 18.3446 31.7824 20 31.7824C21.6554 31.7824 23 30.4377 23 28.7824H28.7857C28.9036 28.7824 29 28.6859 29 28.5681V27.7109C29 27.2368 28.617 26.8538 28.1429 26.8538ZM20 30.0681C19.2902 30.0681 18.7143 29.4922 18.7143 28.7824H21.2857C21.2857 29.4922 20.7098 30.0681 20 30.0681ZM14.4286 26.8538V17.7467C14.4286 16.2574 15.0071 14.8592 16.0598 13.8065C17.1125 12.7538 18.5107 12.1752 20 12.1752C21.4893 12.1752 22.8875 12.7538 23.9402 13.8065C24.9929 14.8592 25.5714 16.2574 25.5714 17.7467V26.8538H14.4286Z"
      fill="#4DABD1"
    />
    <rect x="21.25" y="7.25" width="11.5" height="11.5" rx="5.75" fill="#F87171" />
    <rect
      x="21.25"
      y="7.25"
      width="11.5"
      height="11.5"
      rx="5.75"
      stroke="white"
      strokeWidth="1.5"
    />
  </svg>
)
