// import { Button } from "antd"
import { useTranslation } from "react-i18next"
// import { useNavigate } from "react-router-dom"

export default function MyPagination({ title, canGoBack = true, children }) {
  const { t } = useTranslation()
  // const navigate = useNavigate()
  return (
    <div className="flex items-center mb-4">
      {/* {canGoBack ? (
        <Button
          type="text"
          size="large"
          icon={
            <svg
              xmlns="http://www.w3.org/2000/svg"
              fill="none"
              viewBox="0 0 24 24"
              strokeMiterlimit={1.5}
              stroke="currentColor"
              className="w-6 h-6"
            >
              <path strokeLinecap="round" strokeLinejoin="round" d="M15.75 19.5 8.25 12l7.5-7.5" />
            </svg>
          }
          onClick={() => navigate(-1)}
        />
      ) : null} */}

      <div className="flex justify-between items-center gap-3 w-max">
        <div className="font-semibold text-2xl text-primary-700">{t(title)}</div>
        {children}
      </div>
    </div>
  )
}
