import { Form, Input, Button, Checkbox } from "antd"
import { EyeTwoTone, EyeInvisibleOutlined } from "@ant-design/icons"
import { apiCore, apiUser } from "api"
import { useStore } from "components/ui"
import { useState, useEffect, useRef } from "react"
import { useNavigate } from "react-router-dom"
import { setCookieAuth } from "lib/cookie"
import LabelInput from "components/ui/LabelInput"
import ModalVerifyCode from "components/ModalVerifyCode"
import ModalPopupConfirm from "components/ModalPopupConfirm"
import { isEmailValid, hasWhiteSpace } from "lib/validate"
import AlertError from "components/AlertError"
import "./Login.scss"
import { getThemeConfigs } from "lib/function"
import { useTranslation } from "react-i18next"

const LoginForm = ({ user, onNext, onNextFirstLogin, onNextToSpecific2Fa, onForgot }) => {
  const { t } = useTranslation()
  const navigate = useNavigate()
  const { setAuthStore } = useStore()
  const rememberUsername = localStorage.getItem("remember_username")
  const rememberPassword = localStorage.getItem("remember_password")
  const [loading, setLoading] = useState(false)
  const [error, setError] = useState("")
  const [isModalOpen, setIsModalOpen] = useState(false)
  const [isModalConfirm, setIsModalConfirm] = useState(false)
  const [userData, setUserData] = useState({})
  const [isSubmited, setIsSubmited] = useState(false)
  const newAuth = useRef()

  const [form] = Form.useForm()
  const formRef = useRef(null)

  // Callback function when the form is submitted
  const onFinish = async (values) => {
    setError("")
    setLoading(true)
    try {
      // Make a login API call using the provided credentials
      const { data } = await apiUser.login(values)

      if (values?.remember) {
        localStorage.setItem("remember_username", values?.username)
        localStorage.setItem("remember_password", values?.password)
      } else {
        localStorage.removeItem("remember_username")
        localStorage.removeItem("remember_password")
      }
      setUserData(data?.user)
      if (!data?.user?.last_login) {
        await apiUser.sendEmail({ email: values.username })
        onNextFirstLogin(values.username)
      } else {
        if (data?.token) {
          fetchBaseApi(data?.token)
        } else {
          if (data?.systerm_2fa) {
            // default two_fa = 4 when systerm_2fa=true
            if (data?.user?.two_fa === 4) {
              onNext(values.username, data?.user)
            } else {
              if (data?.user?.two_fa === 3) {
                onNext(values.username, data?.user)
              } else {
                if (data?.user?.two_fa === 1) {
                  await apiUser.sendEmail({ email: values.username })
                  onNextToSpecific2Fa(values.username, data?.user, data?.user?.two_fa)
                } else {
                  if (!data?.user?.is_scan_qrcode) {
                    setIsModalOpen(true)
                  } else {
                    onNextToSpecific2Fa(values.username, data?.user, data?.user?.two_fa)
                  }
                }
              }
            }
          } else {
            if (data?.user?.two_fa === 3) {
              onNext(values.username, data?.user)
            } else {
              if (data?.user?.two_fa === 1) {
                await apiUser.sendEmail({ email: values.username })
                onNextToSpecific2Fa(values.username, data?.user, data?.user?.two_fa)
              } else {
                if (!data?.user?.is_scan_qrcode) {
                  setIsModalOpen(true)
                } else {
                  onNextToSpecific2Fa(values.username, data?.user, data?.user?.two_fa)
                }
              }
            }
          }
        }
      }
    } catch (error) {
      const { data } = error.response
      setError(data?.error_message[0])
      setLoading(false)
    } finally {
      // setLoading(false)
    }
  }

  async function fetchBaseApi(token) {
    const _token = token?.access_token ? token : newAuth.current
    const { access_token, refresh_token } = _token
    setLoading(true)
    try {
      const resProfile = apiCore.getProfile(access_token)
      const resConfigs = apiCore.getConfigs(access_token)
      const resPermission = apiCore.getPermissions(access_token)
      const res = await Promise.all([resProfile, resConfigs, resPermission])
      let configs = res[1]?.data
      let baseData = {
        user: res[0]?.data,
        settings: configs,
        permission: res[2]?.data?.permissions,
        access_token: access_token,
        settingTheme: getThemeConfigs(configs)
      }

      setIsModalConfirm(false)
      setCookieAuth({ access_token, refresh_token })
      setAuthStore(baseData)
      setLoading(false)
      navigate("/dashboard")

      if (navigator.geolocation) {
        navigator.geolocation.getCurrentPosition(
          async (position) => {
            const { latitude, longitude } = position.coords
            localStorage.setItem(
              "location",
              `https://www.google.com/maps/search/?api=1&query=${latitude},${longitude}`
            )
            console.log("99999999999")

            // await apiCore.getProfile(access_token)
          },
          (error) => {
            console.error("Error getting location:", error)
          }
        )
      } else {
        const error = new Error("Geolocation is not supported by this browser.")
        console.error(error.message)
      }
    } catch (err) {
      setLoading(false)
      console.log(err)
    }
  }

  const onChange = () => {
    setIsSubmited(true)
    setError("")
  }

  // Function to perform a demo login with predefined credentials
  function onLogin() {
    // Hardcoded demo credentials for testing
    const values = {
      username: "tranquangdat1397@gmail.com",
      password: "Huyenbeo@04"
    }
    // Call the onFinish function with the demo credentials
    onFinish(values)
  }

  function handleVerifyCode(token) {
    // setNewAuth(token)
    newAuth.current = token
    setIsModalOpen(false)
    setIsModalConfirm(true)
  }

  // Check if the environment is a demo environment
  const isLocal = window.location.origin === "http://localhost:3000"

  useEffect(() => {
    if (user) {
      setUserData(user)
    }
  }, [user])

  useEffect(() => {
    if (rememberUsername) {
      setIsSubmited(true)
      form.setFieldsValue({
        username: rememberUsername,
        password: rememberPassword,
        remember: true
      })
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [rememberUsername])

  return (
    <div className="">
      <h3 className="text-[24px] text-primary-700 font-bold text-center mt-8 mb-6">
        {t("Log in your account")}
      </h3>

      {error !== "" && <AlertError error={t(error)} />}

      <Form name="normal_login" form={form} ref={formRef} onFinish={onFinish} layout="vertical">
        {/* Input field for the username */}
        <Form.Item
          label={<LabelInput label={t("Email")} required={true} />}
          required={false}
          name="username"
          rules={[
            { required: true, message: t("Email is required") },
            {
              validator: async (rule, value) => {
                if (value && isEmailValid(value)) {
                  return Promise.reject(t("Email is invalid"))
                }
                return Promise.resolve()
              }
            }
          ]}
        >
          <Input placeholder="example@gmail.com" onChange={onChange} />
        </Form.Item>

        {/* Input field for the password */}
        <Form.Item
          label={<LabelInput label={t("Password")} required={true} />}
          name="password"
          required={false}
          rules={[
            { required: true, message: t("Password is required") },
            {
              validator: async (rule, value) => {
                if (value && value.length < 6) {
                  return Promise.reject(t("Your password should have 6 characters or more"))
                }
                if (value && hasWhiteSpace(value)) {
                  return Promise.reject(t("Your password should not have whitespace"))
                }
                return Promise.resolve()
              }
            }
          ]}
        >
          <Input.Password
            // size="large"
            placeholder={t("Enter your password")}
            iconRender={(visible) =>
              visible ? <EyeTwoTone className="text-primary-600" /> : <EyeInvisibleOutlined />
            }
            onChange={onChange}
          />
        </Form.Item>

        <div className="flex items-center justify-between mt-4">
          <Form.Item name="remember" valuePropName="checked" noStyle>
            <Checkbox>{t("Remember login")}</Checkbox>
          </Form.Item>
          {/* eslint-disable-next-line jsx-a11y/anchor-is-valid */}
          <div
            className="text-primary-700 font-bold cursor-pointer hover:text-primary-600"
            onClick={onForgot}
          >
            {t("Forgot password")}
          </div>
        </div>

        <Form.Item>
          <Button
            type="primary"
            size="large"
            htmlType="submit"
            className="w-full mt-5 !rounded-lg"
            loading={loading}
            disabled={!isSubmited}
          >
            {t("Log in")}
          </Button>
        </Form.Item>
      </Form>

      <ModalVerifyCode
        user={userData}
        setUser={(v) => setUserData(v)}
        visible={isModalOpen}
        onSubmit={handleVerifyCode}
        onClose={() => setIsModalOpen(false)}
      />
      <ModalPopupConfirm
        visible={isModalConfirm}
        title={t("Two-factor authentication is on")}
        desctiption={t(
          "Now when ever you sign in, we will ask you for a code after you enter email address and password"
        )}
        loading={loading}
        onSubmit={fetchBaseApi}
      />
    </div>
  )
}

export default LoginForm
