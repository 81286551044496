import { Button, Radio } from "antd"
import { apiUser } from "api"
import { useState, useEffect } from "react"
import axios from "axios"
import { setCookieAuth } from "lib/cookie"
import { useStore } from "components/ui"
import { useNavigate } from "react-router-dom"
import ModalVerifyCode from "components/ModalVerifyCode"
import ModalPopupConfirm from "components/ModalPopupConfirm"
import { useTranslation } from "react-i18next"

const baseDomain = process.env.REACT_APP_ID_URL
const baseDomainCore = process.env.REACT_APP_CORE_URL

const TwoFactorAuthen = ({ user, email, onNext, onBack }) => {
  const { t } = useTranslation()
  const navigate = useNavigate()
  const { setAuthStore } = useStore()
  const [loading, setLoading] = useState(false)
  const [value, setValue] = useState(1)
  const [isModalOpen, setIsModalOpen] = useState(false)
  const [isModalConfirm, setIsModalConfirm] = useState(false)
  const [userData, setUserData] = useState({})
  const [newAuth, setNewAuth] = useState({})

  async function fetchBaseApi() {
    const { access_token, refresh_token } = newAuth
    let userInfo = {}
    setLoading(true)
    axios({
      url: `${baseDomain}/api/v1/account/view/profile/`,
      method: "get",
      headers: {
        Authorization: `Bearer ${access_token}`,
        "Content-Type": "application/json"
      }
    })
      .then((response) => {
        const { data } = response.data
        userInfo = data
        return axios({
          url: `${baseDomainCore}/api/v1/staff/configs/?page_num=1&page_size=1000`,
          method: "get",
          headers: {
            Authorization: `Bearer ${access_token}`,
            "Content-Type": "application/json"
          }
        })
      })
      .then((secondResponse) => {
        const { data } = secondResponse
        setLoading(false)

        let baseData = {
          user: userInfo,
          settings: data?.data,
          permission: [],
          access_token: access_token
        }
        setCookieAuth({ access_token, refresh_token })
        setAuthStore(baseData)
        navigate("/dashboard")
      })
      .catch((err) => {
        setLoading(false)
        console.log(err)
      })
  }

  function onChange(e) {
    const { value } = e.target
    setValue(value)
  }

  async function onSubmit() {
    if (value === 1) {
      setLoading(true)
      try {
        await apiUser.sendEmail({ email: email })
        onNext(value)
      } catch (error) {
        console.log(error)
      } finally {
        setLoading(false)
      }
    } else {
      if (!userData?.is_scan_qrcode) {
        setIsModalOpen(true)
      } else {
        onNext(value)
      }
    }
  }

  function handleVerifyCode(token) {
    setNewAuth(token)
    setIsModalOpen(false)
    setIsModalConfirm(true)
  }

  useEffect(() => {
    if (user) {
      setUserData(user)
      // if (user?.systerm_2fa) {
      //   setValue(2)
      // }
    }
  }, [user])

  return (
    <div className="">
      <h3 className="text-[24px] text-blue-dark font-bold text-center mt-8 mb-6">
        {t("Two-Factor Authentication")}
      </h3>

      <div className="text-center text-[#646970]">
        {t(
          "We will protect your account with two-factor authentication. How would you like to receive verification codes?"
        )}
      </div>

      <div className="mt-[16px]">
        <Radio.Group value={value} className="w-full" onChange={onChange}>
          <div className="w-full p-2 bg-[#FEFEFE] border border-[#C2C2C2] rounded-[12px]">
            <Radio
              className="w-full"
              value={1}
              // disabled={userData?.systerm_2fa && userData?.two_fa !== 1}
            >
              <div className="w-full ml-2">
                <div className="h-[20px] text-black font-bold">{t("Email")}</div>
                <div className="mt-2 text-[#646970]">
                  {t("Receive verification codes via email")}
                </div>
              </div>
            </Radio>
          </div>
          <div className="w-full p-2 bg-[#FEFEFE] border border-[#C2C2C2] rounded-[12px] mt-[16px]">
            <Radio className="w-full" value={2}>
              <div className="w-full ml-2">
                <div className="h-[20px] text-black font-bold">{t("Authenticator app")}</div>
                <div className="mt-2 text-[#646970]">
                  {t("Use authenticator app to generate verification codes")}
                </div>
              </div>
            </Radio>
          </div>
        </Radio.Group>
      </div>

      <div className="mt-[32px]">
        <Button
          className="w-full !h-[45px] !text-[20px] !font-[bold]"
          type="primary"
          onClick={onSubmit}
          loading={loading}
        >
          {t("Continue")}
        </Button>
        <div className="text-center mt-[16px]">
          <span>
            {t("Back to")} {/* eslint-disable-next-line jsx-a11y/anchor-is-valid */}
            <a
              className="cursor-pointer text-[#326E86]"
              onClick={() => {
                if (loading) {
                  return
                }
                onBack()
              }}
            >
              {t("Sign in")}
            </a>
          </span>
        </div>
      </div>
      <ModalVerifyCode
        user={userData}
        setUser={(v) => setUserData(v)}
        visible={isModalOpen}
        onSubmit={handleVerifyCode}
        onClose={() => setIsModalOpen(false)}
      />
      <ModalPopupConfirm
        visible={isModalConfirm}
        title={t("Two-factor authentication is on")}
        desctiption={t(
          "Now when ever you sign in, we will ask you for a code after you enter email address and password"
        )}
        loading={loading}
        onSubmit={fetchBaseApi}
      />
    </div>
  )
}

export default TwoFactorAuthen
