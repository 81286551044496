import axios from "axios"
import { notification } from "antd"
import { removeCookies } from "lib/cookie"
import { getCurrentTimezone } from "lib/function"

const baseDomain = process.env.REACT_APP_ID_URL
const baseDomainCore = process.env.REACT_APP_CORE_URL

function getInstanceAxios({ access_token, baseDomain }) {
  const instance = axios.create({
    baseURL: baseDomain
  })

  instance.interceptors.request.use(
    function (config) {
      config.headers = {
        Accept: "application/json",
        "Content-Type": "application/json",
        Timezone: getCurrentTimezone(),
        Location: localStorage.getItem("location")
      }

      if (access_token) config.headers["Authorization"] = `Bearer ${access_token}`
      return config
    },
    function (error) {
      return Promise.reject(error)
    }
  )

  instance.interceptors.response.use(
    function (response) {
      try {
        if (response.status >= 200 && response.status < 300) return response.data
        return Promise.reject(response.data)
      } catch (error) {
        return Promise.reject(error)
      }
    },
    async function (error) {
      if (error.response) {
        const { response } = error
        if (response.status === 401) removeCookies()
        const data = response.data
        if (data && response.config.method !== "get" && response.status !== 500) {
          if (data.error_message) {
            notification.error({
              description: convertMessageError(data.error_message),
              duration: 3
            })
            return Promise.reject(data.error_message)
          }
          if (data.message) {
            notification.error({
              description: data?.message || data?.error_message
            })
            return
          }
          if (data && !data.message && Object.values(data).length > 0) {
            if (data.length > 0) {
              data.forEach((item) => {
                notification.error({
                  description: item,
                  duration: 3
                })
              })
            } else {
              Object.values(data).forEach((item) => {
                notification.error({
                  description: item?.length > 0 ? item[0] : "N/A",
                  duration: 3
                })
              })
            }
          } else {
            notification.error({
              description: data?.message || data?.error_message
            })
          }
        }
      }
      if (error?.response?.data?.error_code === "ERR_MNT_503") {
        if (window.location.pathname !== "/maintenance") {
          window.location.href = "/maintenance"
        }
      }
      return Promise.reject(error)
    }
  )

  return instance
}

function convertMessageError(errors) {
  var str = []
  for (let i = 0; i < errors.length; i++) {
    if (typeof errors[i] === "string") {
      str.push(`${errors[i]}. `)
    } else {
      let k = Object.keys(errors[i])[0]
      str.push(k + " : " + errors[i][k])
    }
  }
  return str.map((i) => {
    return <div>{i}</div>
  })
}

let resource = `/api/v1`
function getProfile(access_token, data) {
  return getInstanceAxios({ access_token, baseDomain }).get(
    `${resource}/account/view/profile/`,
    data
  )
}

function getConfigs(access_token, data) {
  return getInstanceAxios({ access_token, baseDomain: baseDomainCore }).get(
    `${resource}/client/configs/`,
    data
  )
}
function getPermissions(access_token, data) {
  return getInstanceAxios({ access_token, baseDomain }).get(`${resource}/permissions/`, data)
}

const api = { getProfile, getConfigs, getPermissions }
export default api
