import { Form, Input, Button } from "antd"
import { useState } from "react"
import { apiUser } from "api"
import LabelInput from "components/ui/LabelInput"
import { isEmailValid } from "lib/validate"
import "./Login.scss"
import AlertError from "components/AlertError"
import { useTranslation } from "react-i18next"

const ForgotPassword = ({ onNext, onBack }) => {
  const { t } = useTranslation()
  const [loading, setLoading] = useState(false)
  const [error, setError] = useState("")
  const [isSubmited, setIsSubmited] = useState(false)

  // Callback function when the form is submitted
  const onFinish = async (values) => {
    setLoading(true)
    try {
      // Make a login API call using the provided credentials
      await apiUser.sendEmail({ email: values.username })
      onNext(values?.username)
    } catch (error) {
      const { data } = error.response
      setError(t(data?.error_message[0]))
    } finally {
      setLoading(false)
    }
  }

  const onChange = () => {
    setIsSubmited(true)
    setError("")
  }

  return (
    <div className="">
      <h3 className="text-[24px] text-blue-dark font-bold text-center mt-8 mb-6">
        {t("Forgot password")}
      </h3>

      {error !== "" && <AlertError error={error} />}

      <div className="text-center text-[#646970]">
        {t(
          "Enter the email that address associated with your account and we will send you a verification code"
        )}
      </div>

      <Form
        name="normal_login"
        initialValues={{ remember: true }}
        onFinish={onFinish}
        layout="vertical"
      >
        {/* Input field for the username */}
        <Form.Item
          label={<LabelInput label={t("Email")} required={true} />}
          required={false}
          name="username"
          rules={[
            { required: true, message: t("Email is required") },
            {
              validator: async (rule, value) => {
                if (value && isEmailValid(value)) {
                  return Promise.reject(t("Email is invalid"))
                }
                return Promise.resolve()
              }
            }
          ]}
        >
          <Input size="large" placeholder="example@gmail.com" onChange={onChange} />
        </Form.Item>

        {/* Submit button */}
        <Form.Item>
          <Button
            type="primary"
            size="large"
            htmlType="submit"
            className="w-full mt-5 !rounded-lg"
            loading={loading}
            disabled={!isSubmited}
          >
            {t("Continue")}
          </Button>
        </Form.Item>
      </Form>

      <div className="text-center mt-[16px]">
        <span>
          {t("Back to")} {/* eslint-disable-next-line jsx-a11y/anchor-is-valid */}
          <a className="cursor-pointer text-primary-700" onClick={onBack}>
            {t("Log in")}
          </a>
        </span>
      </div>
    </div>
  )
}

export default ForgotPassword
