import Client from "../client/Client"
import version from "../version"
const resource = version + "/client/users"

function gets(params) {
  return Client.get(`${resource}/`, { params })
}
function get(id) {
  return Client.get(`${resource}/${id}/`)
}
function create(data) {
  return Client.post(`${resource}/`, data)
}
function update(id, data) {
  return Client.put(`${resource}/${id}/`, data)
}
function changeStatus(id, data) {
  return Client.put(`${resource}/${id}/change-status/`, data)
}
function changePassword(id, data) {
  return Client.put(`${resource}/${id}/change-password/`, data)
}
function remove(id) {
  return Client.delete(`${resource}/${id}/`)
}
function exportList(data) {
  return Client.post(`${resource}/export-csv/`, data)
}
function getProfile() {
  return Client.get(`${version}/client/profile/`)
}
const api = {
  gets,
  get,
  create,
  update,
  changeStatus,
  changePassword,
  remove,
  exportList,
  getProfile
}
export default api
